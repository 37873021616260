import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Tokenlist from '../components/Tokenlist';
import toks from '../data/tokens.json';

export default function Add({ walletAction, isConnected, isLoading }) {
    const location = useLocation()
    const [isAll, setIsAll] = useState(location.pathname.substring(location.pathname.length - 2).toLowerCase())
    const [isTokensOpen, setIsTokensOpen] = useState(false)
    const [tokens, setTokens] = useState(toks)
    const [upSelected, setUpSelected] = useState(toks[0])
    const [downSelected, setDownSelected] = useState(toks[5])

    const setSelected = (selected, isUp) => isUp ? setUpSelected(selected) : setDownSelected(selected)

    const toggleTokens = () => isTokensOpen ? closeTokens() : openTokens()

    const closeTokens = () => setIsTokensOpen(false)

    const openTokens = (dir = true) => setIsTokensOpen(dir)
    return (
        <>
            {isTokensOpen ? <Tokenlist isUp={isTokensOpen !== "down"} setSelected={setSelected} close={closeTokens} tokens={tokens} upSelected={upSelected} downSelected={downSelected} /> : null}
            <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
                <Navbar walletAction={walletAction} />
                <div className="sc-gKHVLF sc-ehwEHI oUgzS ggQwmN">
                    <div className="sc-djLfiL hMZhUc">
                        <div className="sc-eVRJAz uaifU">
                            <div className="sc-bYUneI fbWnpo sc-ihxOxR iRZkqd">
                                <div className="sc-gKHVLF sc-kMbQoj UlmxL dywlDY">
                                    <div className="sc-gKHVLF sc-iBAaJG sc-hlaTbY UlmxL lbwIxd OigNq">
                                        <div className="sc-gKHVLF sc-iBAaJG bTyHhM feFGPx">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <a href="#/liquidity">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="22px"
                                                        color="text"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="sc-bcPKhP cvmRst"
                                                    >
                                                        <path d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" />
                                                    </svg>
                                                </a>
                                                <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                    <div className="sc-grXZZQ CdzCT">Add Liquidity</div>
                                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                                        <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    color="textSubtle"
                                                                    width="16px"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="sc-bcPKhP Eouil"
                                                                >
                                                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <div variant="subtle" className="sc-bBbNsw kQLbxS">
                                                    <button
                                                        variant="subtle"
                                                        scale="sm"
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v1" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        onClick={() => setIsAll("v1")}
                                                    >
                                                        V1
                                                    </button>
                                                    <button
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v2" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        variant="subtle"
                                                        scale="sm"
                                                        onClick={() => setIsAll("v2")}
                                                    >
                                                        V2
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sc-ilpitK fxqqA-d">
                                        <div gap="20px" className="sc-gVQcLu iyIFxD">
                                            <div id="add-liquidity-input-tokena" className="sc-gKHVLF UlmxL">
                                                <div className="sc-eJmhDG lhZUsi">
                                                    <div className="sc-fFJcZz iXEjtC">
                                                        <div className="sc-gIvWvA iHXtSR">
                                                            <input
                                                                placeholder={0.0}
                                                                className="sc-isKtiH jdbMyp"
                                                                type="text"
                                                                inputMode="numeric"
                                                            />
                                                            <button
                                                                className="sc-hLclGa bUZmol sc-kATywh lppJEz open-currency-select-button"
                                                                variant="text"
                                                                scale="sm"
                                                                onClick={openTokens}
                                                            >
                                                                <img
                                                                    src={upSelected.img}
                                                                    className="sc-lirSmk ilQYRV"
                                                                    style={{ marginRight: 8 }}
                                                                    alt='PulseX'
                                                                />
                                                                <div id="pair" className="sc-grXZZQ ciLqzo">
                                                                    {upSelected.symbol}
                                                                </div>
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    color="text"
                                                                    width="20px"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="sc-bcPKhP cvmRst"
                                                                >
                                                                    <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className="sc-eAmRia igErbW">
                                                            <div className="sc-grXZZQ cdHhbk" />
                                                            <div className="sc-gKHVLF sc-iBAaJG bFkCsk kDlpqT">
                                                                {isConnected ? <div
                                                                    className="sc-grXZZQ dGxDRB"
                                                                    style={{ display: 'inline', cursor: 'pointer' }}
                                                                >
                                                                    Balance: {upSelected.balance}
                                                                </div> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc-CNCmo sc-dxlEDv ggbjm cTeGCR">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    width="16px"
                                                    color="text"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP cvmRst"
                                                >
                                                    <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" />
                                                </svg>
                                            </div>
                                            <div id="add-liquidity-input-tokenb" className="sc-gKHVLF UlmxL">
                                                <div className="sc-eJmhDG lhZUsi">
                                                    <div className="sc-fFJcZz iXEjtC">
                                                        <div className="sc-gIvWvA iHXtSR">
                                                            <input
                                                                placeholder={0.0}
                                                                className="sc-isKtiH jdbMyp"
                                                                type="text"
                                                                inputMode="numeric"
                                                            />
                                                            <button
                                                                className="sc-hLclGa bUZmol sc-kATywh lppJEz open-currency-select-button"
                                                                variant="text"
                                                                scale="sm"
                                                                onClick={() => openTokens('down')}
                                                            >
                                                                <img
                                                                    className="sc-hJNuel oqEAr"
                                                                    alt="PLSX logo"
                                                                    src={downSelected.img}
                                                                    style={{
                                                                        marginRight: 8,
                                                                        backgroundBlendMode: 'normal!important',
                                                                        backgroundClip: 'content-box!important',
                                                                        backgroundPosition: '50% 50%!important',
                                                                        backgroundColor: 'rgba(0,0,0,0)!important',
                                                                        backgroundImage: 'var(--sf-img-13)!important',
                                                                        backgroundSize: '100% 100%!important',
                                                                        backgroundOrigin: 'content-box!important',
                                                                        backgroundRepeat: 'no-repeat!important',
                                                                    }}
                                                                />
                                                                <div id="pair" className="sc-grXZZQ ciLqzo">
                                                                    {downSelected.symbol}
                                                                </div>
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    color="text"
                                                                    width="20px"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="sc-bcPKhP cvmRst"
                                                                >
                                                                    <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z" />
                                                                </svg>
                                                            </button>
                                                            <div
                                                                className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh"
                                                                style={{ gap: 4 }}
                                                            >
                                                                <div>
                                                                    <button
                                                                        className="sc-hLclGa bUZmol sc-eDnVMP cZbnq"
                                                                        scale="sm"
                                                                        variant="text"
                                                                        style={{ width: 'auto', position: 'relative' }}
                                                                    >
                                                                        <svg
                                                                            viewBox="0 0 24 24"
                                                                            color="textSubtle"
                                                                            width="16px"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="sc-bcPKhP Eouil"
                                                                        >
                                                                            <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <button
                                                                    variant="text"
                                                                    p={0}
                                                                    height="auto"
                                                                    width="fit-content"
                                                                    scale="md"
                                                                    className="sc-hLclGa fQjRHI"
                                                                >
                                                                    <svg
                                                                        viewBox="0 0 40 40"
                                                                        width="16px"
                                                                        ml="0px"
                                                                        color="text"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="sc-bcPKhP ehJGMc"
                                                                    >
                                                                        <path
                                                                            d="M36.0112 3.33337L22.1207 13.6277L24.7012 7.56091L36.0112 3.33337Z"
                                                                            fill="#E17726"
                                                                        />
                                                                        <path
                                                                            d="M4.00261 3.33337L17.7558 13.7238L15.2989 7.56091L4.00261 3.33337Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M31.0149 27.2023L27.3227 32.8573L35.2287 35.0397L37.4797 27.3258L31.0149 27.2023Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M2.53386 27.3258L4.77116 35.0397L12.6772 32.8573L8.9987 27.2023L2.53386 27.3258Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M12.2518 17.6496L10.0419 20.9712L17.8793 21.3281L17.6048 12.8867L12.2518 17.6496Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M27.762 17.6494L22.3129 12.7905L22.1207 21.3279L29.9581 20.9711L27.762 17.6494Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M12.6772 32.8574L17.3989 30.5652L13.336 27.3809L12.6772 32.8574Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M22.6009 30.5652L27.3226 32.8574L26.6637 27.3809L22.6009 30.5652Z"
                                                                            fill="#E27625"
                                                                        />
                                                                        <path
                                                                            d="M27.3226 32.8575L22.6009 30.5653L22.9715 33.6399L22.9303 34.9301L27.3226 32.8575Z"
                                                                            fill="#D5BFB2"
                                                                        />
                                                                        <path
                                                                            d="M12.6772 32.8575L17.0694 34.9301L17.042 33.6399L17.3989 30.5653L12.6772 32.8575Z"
                                                                            fill="#D5BFB2"
                                                                        />
                                                                        <path
                                                                            d="M17.1518 25.3495L13.2262 24.1965L15.9988 22.92L17.1518 25.3495Z"
                                                                            fill="#233447"
                                                                        />
                                                                        <path
                                                                            d="M22.848 25.3495L24.001 22.92L26.801 24.1965L22.848 25.3495Z"
                                                                            fill="#233447"
                                                                        />
                                                                        <path
                                                                            d="M12.6773 32.8573L13.3635 27.2023L8.99876 27.3258L12.6773 32.8573Z"
                                                                            fill="#CC6228"
                                                                        />
                                                                        <path
                                                                            d="M26.6364 27.2023L27.3227 32.8573L31.0149 27.3258L26.6364 27.2023Z"
                                                                            fill="#CC6228"
                                                                        />
                                                                        <path
                                                                            d="M29.9581 20.9709L22.1207 21.3278L22.8482 25.3495L24.0011 22.92L26.8012 24.1965L29.9581 20.9709Z"
                                                                            fill="#CC6228"
                                                                        />
                                                                        <path
                                                                            d="M13.2263 24.1965L15.9989 22.92L17.1519 25.3495L17.8793 21.3278L10.0419 20.9709L13.2263 24.1965Z"
                                                                            fill="#CC6228"
                                                                        />
                                                                        <path
                                                                            d="M10.0419 20.9709L13.3361 27.3809L13.2263 24.1965L10.0419 20.9709Z"
                                                                            fill="#E27525"
                                                                        />
                                                                        <path
                                                                            d="M26.8011 24.1965L26.6638 27.3809L29.958 20.9709L26.8011 24.1965Z"
                                                                            fill="#E27525"
                                                                        />
                                                                        <path
                                                                            d="M17.8793 21.3278L17.1519 25.3494L18.0715 30.0985L18.2637 23.8396L17.8793 21.3278Z"
                                                                            fill="#E27525"
                                                                        />
                                                                        <path
                                                                            d="M22.1205 21.3278L21.7499 23.8258L21.9283 30.0985L22.848 25.3494L22.1205 21.3278Z"
                                                                            fill="#E27525"
                                                                        />
                                                                        <path
                                                                            d="M22.848 25.3496L21.9284 30.0987L22.601 30.5654L26.6638 27.381L26.8011 24.1967L22.848 25.3496Z"
                                                                            fill="#F5841F"
                                                                        />
                                                                        <path
                                                                            d="M13.2262 24.1967L13.336 27.381L17.3989 30.5654L18.0714 30.0987L17.1518 25.3496L13.2262 24.1967Z"
                                                                            fill="#F5841F"
                                                                        />
                                                                        <path
                                                                            d="M22.9303 34.93L22.9715 33.6398L22.6284 33.3378H17.3714L17.042 33.6398L17.0694 34.93L12.6772 32.8574L14.2145 34.1202L17.3302 36.2751H22.6696L25.7853 34.1202L27.3226 32.8574L22.9303 34.93Z"
                                                                            fill="#C0AC9D"
                                                                        />
                                                                        <path
                                                                            d="M22.601 30.5653L21.9284 30.0986H18.0715L17.3989 30.5653L17.0421 33.6399L17.3715 33.3379H22.6285L22.9716 33.6399L22.601 30.5653Z"
                                                                            fill="#161616"
                                                                        />
                                                                        <path
                                                                            d="M36.5875 14.3003L37.7542 8.61779L36.011 3.33337L22.6009 13.2846L27.7618 17.6493L35.0365 19.7768L36.6424 17.8964L35.9424 17.3886L37.0679 16.3728L36.2169 15.7003L37.3287 14.863L36.5875 14.3003Z"
                                                                            fill="#763E1A"
                                                                        />
                                                                        <path
                                                                            d="M2.24573 8.61779L3.42615 14.3003L2.67123 14.863L3.78302 15.7003L2.93202 16.3728L4.05753 17.3886L3.35752 17.8964L4.96343 19.7768L12.2518 17.6493L17.399 13.2846L4.00263 3.33337L2.24573 8.61779Z"
                                                                            fill="#763E1A"
                                                                        />
                                                                        <path
                                                                            d="M35.0365 19.777L27.7619 17.6495L29.958 20.9712L26.6638 27.3811L31.0149 27.3262H37.4797L35.0365 19.777Z"
                                                                            fill="#F5841F"
                                                                        />
                                                                        <path
                                                                            d="M12.2517 17.6495L4.96332 19.777L2.53386 27.3262H8.99869L13.336 27.3811L10.0419 20.9712L12.2517 17.6495Z"
                                                                            fill="#F5841F"
                                                                        />
                                                                        <path
                                                                            d="M22.1205 21.3276L22.6009 13.2843L24.701 7.56067H15.2988L17.3988 13.2843L17.8792 21.3276L18.0577 23.8531L18.0714 30.0984H21.9283L21.9421 23.8531L22.1205 21.3276Z"
                                                                            fill="#F5841F"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="sc-eAmRia igErbW">
                                                            <div className="sc-grXZZQ cdHhbk" />
                                                            <div className="sc-gKHVLF sc-iBAaJG bFkCsk kDlpqT">
                                                                {isConnected ? <div
                                                                    className="sc-grXZZQ dGxDRB"
                                                                    style={{ display: 'inline', cursor: 'pointer' }}
                                                                >
                                                                    Balance: {downSelected.balance}
                                                                </div> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-BbNMx sc-bjKBMW jmzAkG boImKU kEAsbx">
                                                <div className="sc-gKHVLF sc-iuCANo sc-dcMiQZ isAwIc jMhxgW eTugDD">
                                                    <div className="sc-grXZZQ eHtPO">Prices and pool share</div>
                                                </div>{' '}
                                                <div className="sc-gKHVLF sc-BbNMx sc-bjKBMW bIfsTx dobVNr kEAsbx">
                                                    <div gap="md" className="sc-gVQcLu iyIFwq">
                                                        <div className="sc-gKHVLF sc-iuCANo sc-hFHwku UlmxL hBUzgx YWNwz">
                                                            <div justify="center" className="sc-gVQcLu jXfBFC">
                                                                <div className="sc-grXZZQ cdHhbk">3.73463</div>
                                                                <div className="sc-grXZZQ gfRmwo">PLSX per PLS</div>
                                                            </div>
                                                            <div justify="center" className="sc-gVQcLu jXfBFC">
                                                                <div className="sc-grXZZQ cdHhbk">0.267764</div>
                                                                <div className="sc-grXZZQ gfRmwo">PLS per PLSX</div>
                                                            </div>
                                                            <div justify="center" className="sc-gVQcLu jXfBFC">
                                                                <div className="sc-grXZZQ cdHhbk">0%</div>
                                                                <div className="sc-grXZZQ gfRmwo">Share of Pool</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div gap="md" className="sc-gVQcLu iyIFwq">
                                                <button
                                                    className="sc-hLclGa iWOLBh"
                                                    variant="primary"
                                                    scale="md"
                                                    disabled={isLoading ? true : false}
                                                    onClick={walletAction}
                                                >
                                                    {isLoading ? "Loading..." : !isConnected ? "Connect Wallet" : "Sign In"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="sc-gVQcLu kdUUqt"
                                style={{
                                    minWidth: '20rem',
                                    width: '100%',
                                    maxWidth: 400,
                                    marginTop: '1rem',
                                }}
                            >
                                <div className="sc-gKHVLF sc-BbNMx sc-bjKBMW UlmxL iPfIbq kEAsbx">
                                    <div className="sc-grXZZQ eHtPO" style={{ textAlign: 'center' }}>
                                        <img
                                            className="sc-hJNuel oqEAr"
                                            alt="PLSX logo"
                                            src='https://tokens.app.pulsex.com/images/tokens/0x95B303987A60C71504D99Aa1b13B4DA07b0790ab.png'
                                            style={{
                                                backgroundBlendMode: 'normal!important',
                                                backgroundClip: 'content-box!important',
                                                backgroundPosition: '50% 50%!important',
                                                backgroundColor: 'rgba(0,0,0,0)!important',
                                                backgroundImage: 'var(--sf-img-13)!important',
                                                backgroundSize: '100% 100%!important',
                                                backgroundOrigin: 'content-box!important',
                                                backgroundRepeat: 'no-repeat!important',
                                            }}
                                        />
                                        {isAll === "v1" ? "Adding liquidity helps burn PLSX. 100% of the swap fees go the BuyAndBurn contract which pays a 0.1% bounty for those that run it."
                                        : "By adding liquidity you'll earn 0.22% of all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity."}
                                    </div>
                                </div>
                            </div>
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL kAtWFC" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer walletAction={walletAction} />
        </>
    )
}
