import React from 'react';

import toks from '../data/tokens.json';

export default function Tokenlist({ close, tokens, upSelected, downSelected, setSelected, isUp }) {
    if (!tokens) tokens = toks
    if (!upSelected) upSelected = toks[0]
    if (!downSelected) downSelected = toks[5]
    return (
        <div role="dialog">
            <div
                className="sc-eGknfI fsqaXe appear"
                style={{ transform: 'translateX(0px)' }}
            >
                <div role="presentation" className="sc-gKHVLF sc-gisvNi UlmxL jALIdj" />
                <div className="sc-jSwlEQ sc-bhFOXJ sc-iuWDFx eYiExZ lpumgW dCwXMo">
                    <div className="sc-gKHVLF sc-iBAaJG sc-WSdAm UlmxL lbwIxd mwBGt">
                        <div className="sc-gKHVLF sc-iBAaJG sc-ikzIVP UlmxL lbwIxd iYobww">
                            <h2 className="sc-grXZZQ sc-AOXSc jftdDy eONOKz">Select a Token</h2>
                        </div>
                        <button
                            className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                            variant="text"
                            aria-label="Close the dialog"
                            scale="md"
                            onClick={close}
                        >
                            <svg
                                viewBox="0 0 24 24"
                                color="primary"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                                className="sc-bcPKhP bIlHZF"
                            >
                                <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="sc-gKHVLF sc-iBAaJG sc-tIxES sc-eCzbEQ UlmxL lbwIxd jRRtdU hiMvDV">
                        <div gap="16px" className="sc-gVQcLu iyIFyS">
                            <div className="sc-gKHVLF sc-iuCANo UlmxL gdCtnF">
                                <input
                                    id="token-search-input"
                                    placeholder="Search name or paste address"
                                    autoComplete="off"
                                    className="sc-ksJisA bduqlx"
                                />
                            </div>
                        </div>
                        <div className="sc-gKHVLF bFkFJx">
                            <div
                                style={{
                                    position: 'relative',
                                    height: 390,
                                    width: '100%',
                                    overflow: 'auto',
                                    willChange: 'transform',
                                    direction: 'ltr',
                                }}
                            >
                                <div style={{ height: 672, width: '100%' }}>
                                    {tokens.map((val, i) => <div key={i}
                                        className={`sc-gKHVLF sc-iuCANo sc-dcMiQZ sc-bAuVPU UlmxL gdCtnF eTugDD ${val.name?.toLowerCase() === upSelected.name?.toLowerCase() || val.name?.toLowerCase() === downSelected.name?.toLowerCase() ? "fVcpFr" : "hkdxaF"}`}
                                        disabled={val.name?.toLowerCase() === upSelected.name?.toLowerCase() || val.name?.toLowerCase() === downSelected.name?.toLowerCase()}
                                        onClick={() => {
                                            if (!(val.name?.toLowerCase() === upSelected.name?.toLowerCase() || val.name?.toLowerCase() === downSelected.name?.toLowerCase())) {
                                                setSelected(val, isUp);
                                                close()
                                            }
                                        }}
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: i * 56,
                                            height: 56,
                                            width: '100%',
                                        }}
                                    >
                                        <img
                                            src={val.img}
                                            className="sc-lirSmk ivSVpp"
                                            style={{
                                                backgroundBlendMode: 'normal!important',
                                                backgroundClip: 'content-box!important',
                                                backgroundPosition: '50% 50%!important',
                                                backgroundColor: 'rgba(0,0,0,0)!important',
                                                backgroundImage: 'var(--sf-img-12)!important',
                                                backgroundSize: '100% 100%!important',
                                                backgroundOrigin: 'content-box!important',
                                                backgroundRepeat: 'no-repeat!important',
                                            }}
                                            alt='PulseX'
                                        />
                                        <div className="sc-CNCmo ggbjm">
                                            <div className="sc-grXZZQ jftdDy">{val.symbol}</div>
                                            <div className="sc-grXZZQ cvMaNL">{' ' + val.name}</div>
                                        </div>
                                        <div
                                            className="sc-gKHVLF sc-iuCANo sc-itENDl UlmxL gdCtnF ieWjMV"
                                            style={{ justifySelf: 'flex-end' }}
                                        >
                                            <div
                                                title="0"
                                                className="sc-grXZZQ sc-ciaeSe cdHhbk dEogaX"
                                            >
                                                {val.balance || 0}
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        <div className="sc-fkekHa jVPPrp">
                            <button
                                className="sc-hLclGa bUZmol list-token-manage-button"
                                scale="sm"
                                variant="text"
                            >
                                Manage Tokens
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
