import React, { useState } from 'react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

export default function Burn({ walletAction }) {
  const [isV1, setIsV1] = useState(false)
  const toggleV1 = () => isV1 ? closeV1() : openV1()

  const closeV1 = () => setIsV1(false)

  const openV1 = () => setIsV1(true)
  return (
    <>
      <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
        <Navbar walletAction={walletAction} />
        <div className="sc-gKHVLF sc-iBAaJG UlmxL bFuSQM">
          <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr gDrmVh eiQbuK dKnWPh" />
        </div>
        <div className="sc-gKHVLF sc-ehwEHI gWzXuy ggQwmN">
          <div className="sc-djLfiL hMZhUc">
            <div className="sc-gKHVLF sc-cQgRsF UlmxL dDpXfN">
              <div className="sc-gKHVLF kCvrlq sc-kneYQU ivTJXt">
                <div className="sc-gKHVLF sc-iBAaJG UlmxL iRQlRN">
                  <div className="sc-gKHVLF sc-iBAaJG UlmxL iUbIoR">
                    <h2
                      scale="xl"
                      id="info-overview-title"
                      className="sc-grXZZQ sc-AOXSc jYHdts tIlKS"
                    >
                      PulseX Buy &amp; Burn
                    </h2>
                    <h2
                      scale="md"
                      id="info-overview-title"
                      className="sc-grXZZQ sc-AOXSc jYHdts eONOKz"
                    >
                      Select LPs to Buy and Burn PLSX
                    </h2>
                  </div>
                  <div className="sc-gKHVLF sc-iBAaJG sc-izTTDP UlmxL lbwIxd cWgIjm">
                    <div className="sc-bYUneI fbWnpo">
                      <div className="sc-gKHVLF sc-kMbQoj UlmxL dywlDY">
                        <div className="sc-gKHVLF YYxld">
                          <div className="sc-gKHVLF sc-iBAaJG UlmxL feFGPx">
                            <div className="sc-grXZZQ gmHUsc">Burn PLSX</div>
                            <div className="sc-grXZZQ jpyzcZ">
                              Total PLSX burned: 587.67B🔥
                            </div>
                          </div>
                          <div className="sc-grXZZQ gLWoSh">
                            Selected USD value: $0.00
                          </div>
                          <div className="sc-grXZZQ gLWoSh">
                            This would burn: ~0.00 PLSX
                          </div>
                          <div className="sc-grXZZQ jftdDy">
                            Bounty: 0.00 PLSX / ~$0.00
                          </div>
                          <div className="sc-gKHVLF UlmxL">
                            <div className="sc-gKHVLF sc-iBAaJG iPngAL kTgwuC">
                              <button
                                width="100%"
                                variant="primary"
                                scale="md"
                                className="sc-hLclGa jmRqFu"
                                onClick={walletAction}
                              >
                                BURN
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 scale="lg" className="sc-grXZZQ sc-AOXSc cdJwJp cgrRzL">
                  LPs to Burn
                  <div variant="subtle" ml="20px" className="sc-bBbNsw jSPQYd">
                    <button variant="subtle" scale="sm" className={`sc-hLclGa jiLBrK ${isV1 ? "" : "sc-ivDvhZ bnecNP"}`} onClick={openV1}>
                      V1
                    </button>
                    <button
                      className={`sc-hLclGa jiLBrK ${!isV1 ? "" : "sc-ivDvhZ bnecNP"}`}
                      variant="subtle"
                      scale="sm"
                      onClick={closeV1}
                    >
                      V2
                    </button>
                  </div>
                </h2>
                {isV1 ? <div className="sc-gKHVLF sc-iBAaJG sc-bHuWgC UlmxL lbwIxd cbUMHS">
                  <div className="sc-dSGAEy kzebFF">
                    <div className="sc-grXZZQ jexkqp" />
                    <div className="sc-grXZZQ hUrPdy">LPs</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">USD value</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">
                      Token0 amount
                    </div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">
                      Token1 amount
                    </div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">LP amount</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x6B175474E89094C44Da98b954EedeAC495271d0F,0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0x6B175474E89094C44Da98b954EedeAC495271d0F.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">DAI/ATROPA</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$36.17K</div>
                    <div className="sc-grXZZQ cdHhbk">35.67M</div>
                    <div className="sc-grXZZQ cdHhbk">4.17M</div>
                    <div className="sc-grXZZQ cdHhbk">12.18M</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x94534EeEe131840b1c0F61847c572228bdfDDE93,0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">pTGC/WPLS</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.99</div>
                    <div className="sc-grXZZQ cdHhbk">109.61K</div>
                    <div className="sc-grXZZQ cdHhbk">7,899.05</div>
                    <div className="sc-grXZZQ cdHhbk">28.97K</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d,0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d.png"
                        />
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">INC/WPLS</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.96</div>
                    <div className="sc-grXZZQ cdHhbk">0.82</div>
                    <div className="sc-grXZZQ cdHhbk">7,699.30</div>
                    <div className="sc-grXZZQ cdHhbk">79.73</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d,0x95B303987A60C71504D99Aa1b13B4DA07b0790ab"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d.png"
                        />
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0x95B303987A60C71504D99Aa1b13B4DA07b0790ab.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">INC/PLSX</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.35</div>
                    <div className="sc-grXZZQ cdHhbk">0.30</div>
                    <div className="sc-grXZZQ cdHhbk">10.49K</div>
                    <div className="sc-grXZZQ cdHhbk">56.39</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-jMunHZ hqapBp">
                    <div className="sc-fTWLHs jOUYhK">
                      <svg
                        viewBox="0 0 24 24"
                        color="textDisabled"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-bcPKhP klDlNp"
                      >
                        <path d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" />
                      </svg>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">Page 1 of 1</div>
                    <div className="sc-fTWLHs jOUYhK">
                      <svg
                        viewBox="0 0 24 24"
                        color="textDisabled"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-bcPKhP klDlNp"
                      >
                        <path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z" />
                      </svg>
                    </div>
                  </div>
                </div> : <div className="sc-gKHVLF sc-iBAaJG sc-bHuWgC UlmxL lbwIxd cbUMHS">
                  <div className="sc-dSGAEy kzebFF">
                    <div className="sc-grXZZQ jexkqp" />
                    <div className="sc-grXZZQ hUrPdy">LPs</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">USD value</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">Token0 amount</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">Token1 amount</div>
                    <div className="sc-grXZZQ sc-fzSyhj hUrPdy eUKDan">LP amount</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x6B175474E89094C44Da98b954EedeAC495271d0F,0xDf6A16689A893095C721542e5d3CE55bBcc23aC6"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0x6B175474E89094C44Da98b954EedeAC495271d0F.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">DAI/㉣</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$563.11</div>
                    <div className="sc-grXZZQ cdHhbk">563.40K</div>
                    <div className="sc-grXZZQ cdHhbk">221.03K</div>
                    <div className="sc-grXZZQ cdHhbk">350.74K</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x90732c0aBC7b44F6e00c4CC90d29293D1DD01d8B,0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">COWTIP/WPLS</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$328.60</div>
                    <div className="sc-grXZZQ cdHhbk">7,879.96</div>
                    <div className="sc-grXZZQ cdHhbk">2.63M</div>
                    <div className="sc-grXZZQ cdHhbk">137.59K</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/WETH</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$1.28</div>
                    <div className="sc-grXZZQ cdHhbk">10.34K</div>
                    <div className="sc-grXZZQ cdHhbk">10.37K</div>
                    <div className="sc-grXZZQ cdHhbk">9,559.36</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/TEDDY BEAR ㉾</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.0052</div>
                    <div className="sc-grXZZQ cdHhbk">42.60</div>
                    <div className="sc-grXZZQ cdHhbk">193.48K</div>
                    <div className="sc-grXZZQ cdHhbk">864.28</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x2A06a971fE6ffa002fd242d437E3db2b5cC5B433,0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">PTS/WPLS</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.21</div>
                    <div className="sc-grXZZQ cdHhbk">2.41</div>
                    <div className="sc-grXZZQ cdHhbk">1,751.71</div>
                    <div className="sc-grXZZQ cdHhbk">63.85</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xefD766cCb38EaF1dfd701853BFCe31359239F305"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xefD766cCb38EaF1dfd701853BFCe31359239F305.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/DAI</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.49</div>
                    <div className="sc-grXZZQ cdHhbk">4,011.94</div>
                    <div className="sc-grXZZQ cdHhbk">0.24</div>
                    <div className="sc-grXZZQ cdHhbk">20.24</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xeB2CEed77147893Ba8B250c796c2d4EF02a72B68"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/PDRIP</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.63</div>
                    <div className="sc-grXZZQ cdHhbk">4,506.24</div>
                    <div className="sc-grXZZQ cdHhbk">0.040</div>
                    <div className="sc-grXZZQ cdHhbk">13.24</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xDfdc2836FD2E63Bba9f0eE07901aD465Bff4DE71"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/WATT</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.0021</div>
                    <div className="sc-grXZZQ cdHhbk">17.42</div>
                    <div className="sc-grXZZQ cdHhbk">0.39</div>
                    <div className="sc-grXZZQ cdHhbk">2.57</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0x8BDB63033b02C15f113De51EA1C3a96Af9e8ecb5,0xA1077a294dDE1B09bB078844df40758a5D0f9a27"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                      </div>
                      <div className="sc-grXZZQ hrVkmN">AXIS/WPLS</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.077</div>
                    <div className="sc-grXZZQ cdHhbk">0.0000029</div>
                    <div className="sc-grXZZQ cdHhbk">623.61</div>
                    <div className="sc-grXZZQ cdHhbk">0.041</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-dSGAEy kzebFF">
                    <input
                      scale="sm"
                      type="checkbox"
                      className="sc-cCIIiH sc-IkiGZ kgsOva jVynVd"
                      defaultValue="0xA1077a294dDE1B09bB078844df40758a5D0f9a27,0xeDA0073B4Aa1b1B6f9c718c3036551ab46E5Ec32"
                    />
                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                      <div className="sc-eklOhW eIoFMf">
                        <img
                          className="sc-kQUBwl YVJDX"
                          height={16}
                          width={16}
                          alt="token logo"
                          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
                        />
                        <svg
                          viewBox="0 0 24 24"
                          className="sc-bcPKhP cvmRst sc-kQUBwl YVJDX"
                          color="text"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                        </svg>
                      </div>
                      <div className="sc-grXZZQ hrVkmN">WPLS/BEET</div>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">$0.0000066</div>
                    <div className="sc-grXZZQ cdHhbk">0.054</div>
                    <div className="sc-grXZZQ cdHhbk">0.014</div>
                    <div className="sc-grXZZQ cdHhbk">0.00000083</div>
                  </div>
                  <div className="sc-hLecFf fQXGFi" />
                  <div className="sc-jMunHZ hqapBp">
                    <div className="sc-fTWLHs jOUYhK">
                      <svg
                        viewBox="0 0 24 24"
                        color="textDisabled"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-bcPKhP klDlNp"
                      >
                        <path d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" />
                      </svg>
                    </div>
                    <div className="sc-grXZZQ cdHhbk">Page 1 of 1</div>
                    <div className="sc-fTWLHs jOUYhK">
                      <svg
                        viewBox="0 0 24 24"
                        color="textDisabled"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        className="sc-bcPKhP klDlNp"
                      >
                        <path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z" />
                      </svg>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer walletAction={walletAction} />
    </>
  )
}
