import React from 'react';

import { Link } from 'react-router-dom';

import NavbarMini from './NavbarMini';

export default function Footer({ walletAction }) {
    return (
        <>
            <NavbarMini walletAction={walletAction} />
            <div className="sc-iJfdHH kMMekj">
                <div />
            </div>
            <div className="sc-gKHVLF dwcLwJ">
                <div className="sc-gKHVLF sc-hLqnsC bLwGIa hzkBsQ">
                    <div className="sc-grXZZQ kueTgw">v1.0.5</div>
                    <Link
                        rel="noreferrer noopener"
                        to="/"
                        target="_blank"
                    >
                        <div
                            to="https://app.pulsex.com"
                            className="sc-grXZZQ sc-fXitXF dZrkdn iyPLPW"
                        >
                            Check for update
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}
