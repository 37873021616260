import './App.css';
import './styles.css';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  HashRouter,
  Route,
  Routes,
} from 'react-router-dom';

import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';

import { GatekeeperProvider, useGatekeeper } from "./context/Gatekeeper";
import useWeb3Actions from './components/hooks/useWeb3Actions';

import Add from './page/Add.js';
import Burn from './page/Burn.js';
import Farms from './page/Farms.js';
import Find from './page/Find.js';
import Home from './page/Home.js';
import Info from './page/Info.js';
import Liquidity from './page/Liquidity';
import Pools from './page/Pools.js';
import {
  mainnet,
  pulsechain,
} from './util/chains';

const metadata = {
  name: 'PulseX',
  description: '',
  url: 'https://pulsex.com/',
  icons: ['https://app-pulsex.co/logo.png']
}

const projectId = process.env.REACT_APP_PROJECT_ID

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 369 }),
  chains: [ pulsechain, mainnet ],
  projectId
})

const App = () => {
  const { open } = useWeb3Modal()
  const { isConnected } = useWeb3ModalAccount()
  //const { isLoading, setIsLoading } = useGatekeeper();

  const [isLoading, setIsLoading] = useState(false)
  const { action } = useWeb3Actions({ isLoading, setIsLoading })

  const connectWallet = (args) => {
    try {
      open(args)
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  const walletAction = async () => {
    if (!isConnected) return connectWallet();
    try {
      setIsLoading(true);
      await action();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };



  return (
      <HashRouter>
        <GatekeeperProvider>   
          <Routes>
            <Route index element={<Home walletAction={walletAction} isConnected={isConnected} isLoading={isLoading} setIsLoading={setIsLoading}/>} />
            <Route path="/farms" element={<Farms walletAction={walletAction} />} />
            <Route path="/farms/history" element={<Farms walletAction={walletAction} isFinished={true} />} />
            <Route path="/pools" element={<Pools walletAction={walletAction} />} />
            <Route path="/pools/history" element={<Pools walletAction={walletAction} isFinished={true} />} />
            <Route path="/burn" element={<Burn walletAction={walletAction} />} />
            <Route path="/liquidity" element={<Liquidity walletAction={walletAction} isConnected={isConnected} />} />
            <Route path="/info/v2" element={<Info walletAction={walletAction} isV2={true} />} />
            <Route path="/info/v2/pools" element={<Info isV2={true} isPools={true} walletAction={walletAction} />} />
            <Route path="/info/v2/tokens" element={<Info isTokens={true} isV2={true} walletAction={walletAction} />} />
            <Route path="/info/pools" element={<Info isPools={true} walletAction={walletAction} />} />
            <Route path="/info/tokens" element={<Info isTokens={true} walletAction={walletAction} />} />
            <Route path="/info/*" element={<Info walletAction={walletAction} />} />
            <Route path="/add/*" element={<Add walletAction={walletAction} isConnected={isConnected} isLoading={isLoading} />} />
            <Route path="/find" element={<Find walletAction={walletAction} />} />
            <Route path="*" element={<Home walletAction={walletAction} isConnected={isConnected} isLoading={isLoading}/>} />
          </Routes>
        </GatekeeperProvider>   
      </HashRouter>
  );
}

export default App;
