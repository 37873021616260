import React, { useState } from 'react';

export default function Settings({ close }) {
    const [slippage, setSlippage] = useState('')
    return (
        <div role="dialog">
            <div
                className="sc-eGknfI fsqaXe appear"
                style={{ transform: 'translateX(0px)' }}
            >
                <div role="presentation" className="sc-gKHVLF sc-gisvNi UlmxL jALIdj" />
                <div
                    className="sc-jSwlEQ sc-bhFOXJ eYiExZ lpumgW"
                    style={{ overflow: 'visible' }}
                >
                    <div className="sc-gKHVLF eEiGLQ" style={{ maxWidth: 420 }}>
                        <div className="sc-gKHVLF sc-iBAaJG sc-WSdAm kMWBSE lbwIxd cnlaMD">
                            <div className="sc-gKHVLF sc-iBAaJG sc-ikzIVP UlmxL lbwIxd iYobww">
                                <h2 className="sc-grXZZQ sc-AOXSc jftdDy eONOKz">Settings</h2>
                            </div>
                            <button
                                className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                                variant="text"
                                aria-label="Close the dialog"
                                scale="md"
                                onClick={close}
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    color="primary"
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sc-bcPKhP bIlHZF"
                                >
                                    <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
                                </svg>
                            </button>
                        </div>
                        <div
                            className="sc-gKHVLF sc-iBAaJG sc-tIxES gCFhHB lbwIxd jRRtdU"
                            style={{ alignItems: 'normal' }}
                        >
                            <div className="sc-gKHVLF sc-iBAaJG sc-fYXynr UlmxL lbwIxd dqeRXk">
                                <div className="sc-gKHVLF sc-iBAaJG egwnpc izyNuh">
                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL izyNuh">
                                        <div className="sc-gKHVLF sc-iBAaJG hDjHqY izyNuh">
                                            <div className="sc-gKHVLF sc-iBAaJG fxCCop lbwIxd">
                                                <div className="sc-grXZZQ cdHhbk">Slippage Tolerance</div>
                                                <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            color="textSubtle"
                                                            width="16px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="sc-bcPKhP Eouil"
                                                        >
                                                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL jpgsja">
                                                <button
                                                    mt="4px"
                                                    mr="4px"
                                                    scale="sm"
                                                    variant="tertiary"
                                                    className={parseFloat(slippage) === 0.1 ? "sc-hLclGa ipNnAC" : "sc-hLclGa biqbov"}
                                                    onClick={() => setSlippage('0.1')}
                                                >
                                                    0.1%
                                                </button>
                                                <button
                                                    mt="4px"
                                                    mr="4px"
                                                    scale="sm"
                                                    variant="primary"
                                                    className={!slippage || parseFloat(slippage) === 0.5 ? "sc-hLclGa ipNnAC" : "sc-hLclGa biqbov"}
                                                    onClick={() => setSlippage('')}
                                                >
                                                    0.5%
                                                </button>
                                                <button
                                                    mr="4px"
                                                    mt="4px"
                                                    scale="sm"
                                                    variant="tertiary"
                                                    className={parseInt(slippage) === 1 ? "sc-hLclGa ipNnAC" : "sc-hLclGa biqbov"}
                                                    onClick={() => setSlippage('1.0')}
                                                >
                                                    1.0%
                                                </button>
                                                <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                                    <div className="sc-gKHVLF blApOk">
                                                        <input
                                                            inputMode="decimal"
                                                            pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                                                            placeholder="0.50"
                                                            className="sc-ksJisA cLyLYg"
                                                            value={slippage}
                                                            onChange={e => setSlippage(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="sc-grXZZQ iqJzZc">%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sc-gKHVLF sc-iBAaJG hDjHqY fTBGgZ">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                                <div className="sc-grXZZQ cdHhbk">Tx deadline (mins)</div>
                                                <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            color="textSubtle"
                                                            width="16px"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="sc-bcPKhP Eouil"
                                                        >
                                                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <div className="sc-gKHVLF ZXeRG">
                                                    <input
                                                        inputMode="numeric"
                                                        pattern="^[0-9]+$"
                                                        placeholder={20}
                                                        className="sc-ksJisA cLyLYg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-gKHVLF sc-iBAaJG hDjHqY fTBGgZ">
                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                        <div className="sc-grXZZQ cdHhbk">AutoRouter</div>
                                        <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    color="textSubtle"
                                                    width="16px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP Eouil"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div scale="md" className="sc-hZVsLS gnvGyY">
                                        <input
                                            scale="md"
                                            id="toggle-smartrouter-button"
                                            type="checkbox"
                                            className="sc-dSKLDe bhEJYo"
                                            defaultChecked
                                        />
                                        <div scale="md" className="sc-ugnvf hmnjHQ" />
                                    </div>
                                </div>
                                <div className="sc-gKHVLF sc-iBAaJG hDjHqY fTBGgZ">
                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                        <div className="sc-grXZZQ cdHhbk">Expert Mode</div>
                                        <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    color="textSubtle"
                                                    width="16px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP Eouil"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div scale="md" className="sc-hZVsLS gnvGyY">
                                        <input
                                            scale="md"
                                            id="toggle-expert-mode-button"
                                            type="checkbox"
                                            className="sc-dSKLDe bhEJYo"
                                        />
                                        <div scale="md" className="sc-ugnvf dVKKmM" />
                                    </div>
                                </div>
                                <div className="sc-gKHVLF sc-iBAaJG hDjHqY fTBGgZ">
                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                        <div className="sc-grXZZQ cdHhbk">Disable Multihops</div>
                                        <div className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh sc-jQXlCi hwNNwp">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    color="textSubtle"
                                                    width="16px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP Eouil"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 16H13V18H11V16ZM12.61 6.04C10.55 5.74 8.73 7.01 8.18 8.83C8 9.41 8.44 10 9.05 10H9.25C9.66 10 9.99 9.71 10.13 9.33C10.45 8.44 11.4 7.83 12.43 8.05C13.38 8.25 14.08 9.18 14 10.15C13.9 11.49 12.38 11.78 11.55 13.03C11.55 13.04 11.54 13.04 11.54 13.05C11.53 13.07 11.52 13.08 11.51 13.1C11.42 13.25 11.33 13.42 11.26 13.6C11.25 13.63 11.23 13.65 11.22 13.68C11.21 13.7 11.21 13.72 11.2 13.75C11.08 14.09 11 14.5 11 15H13C13 14.58 13.11 14.23 13.28 13.93C13.3 13.9 13.31 13.87 13.33 13.84C13.41 13.7 13.51 13.57 13.61 13.45C13.62 13.44 13.63 13.42 13.64 13.41C13.74 13.29 13.85 13.18 13.97 13.07C14.93 12.16 16.23 11.42 15.96 9.51C15.72 7.77 14.35 6.3 12.61 6.04Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div scale="md" className="sc-hZVsLS gnvGyY">
                                        <input
                                            scale="md"
                                            id="toggle-disable-multihop-button"
                                            type="checkbox"
                                            className="sc-dSKLDe bhEJYo"
                                        />
                                        <div scale="md" className="sc-ugnvf dVKKmM" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
