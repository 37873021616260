import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

export default function Pools({ walletAction, isFinished }) {
  const [isGrid, setIsGrid] = useState(false)
  const toggleGrid = () => isGrid ? closeGrid() : openGrid()

  const closeGrid = () => setIsGrid(false)

  const openGrid = () => setIsGrid(true)
  return (
    <>
      <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
        <Navbar walletAction={walletAction} />
        <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr gDrmVh eiQbuK dKnWPh">
          <div className="sc-gKHVLF fHszqz">
            <div className="sc-grFOgM gZNJiV">
              <Link to="/farms" className="sc-ZPwkx dQBfPj">
                Farms
              </Link>
            </div>
          </div>
          <div className="sc-gKHVLF fHszqz">
            <div className="sc-grFOgM dkUmA-D">
              <Link to="/pools" className="sc-ZPwkx hrbPHn">
                Pools
              </Link>
            </div>
          </div>
        </div>
        <div className="sc-gKHVLF sc-ehwEHI gWzXuy ggQwmN">
          <div className="sc-djLfiL hMZhUc">
            <div className="sc-gKHVLF sc-erzSMd UlmxL hVWvWi">
              <div className="sc-gKHVLF kCvrlq sc-zcqLw fArIlw">
                <div className="sc-gKHVLF sc-iBAaJG UlmxL iRQlRN">
                  <div className="sc-gKHVLF sc-iBAaJG faBRVw PAnLm">
                    <h1 scale="xxl" className="sc-grXZZQ sc-AOXSc cCVVyO jaKpQV">
                      PLSX Pools
                    </h1>
                    <h2 scale="md" className="sc-grXZZQ sc-AOXSc jftdDy eONOKz">
                      Stake PLSX and earn other tokens
                    </h2>
                  </div>
                </div>
                <div className="sc-lqlwp fMoAMc">
                  <div className="sc-hRhbag cBzJOQ">
                    <div className="sc-czqJdR bIOANS">
                      <div variant="subtle" className="sc-bBbNsw kQLbxS">
                        <Link
                          variant="subtle"
                          scale="sm"
                          className={`sc-hLclGa jiLBrK ${!isFinished ? "" : "sc-ivDvhZ bnecNP"}`}
                          to="/pools"
                        >
                          Live
                        </Link>
                        <span className="sc-jIQHsi eglvmN">
                          <Link
                            className={`sc-hLclGa jiLBrK ${isFinished ? "" : "sc-ivDvhZ bnecNP"}`}
                            variant="subtle"
                            id="finished-pools-button"
                            scale="sm"
                            to="/pools/history"
                          >
                            Finished
                          </Link>
                          <span color="failure" className="sc-ZiJuh iQNkzI" />
                        </span>
                      </div>
                    </div>
                    <div className="sc-dXhYVs kqGlCK">
                      <button
                        className="sc-hLclGa bUZmol sc-eDnVMP cZbnq"
                        variant="text"
                        scale="sm"
                        id="clickPoolCardView"
                        onClick={openGrid}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          color="textDisabled"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                          className={!isGrid ? "sc-bcPKhP klDlNp" : "sc-bcPKhP bIlHZF"}
                        >
                          <path d="M4.5 12H7.5C8.05 12 8.5 11.55 8.5 11V7C8.5 6.45 8.05 6 7.5 6H4.5C3.95 6 3.5 6.45 3.5 7V11C3.5 11.55 3.95 12 4.5 12ZM4.5 19H7.5C8.05 19 8.5 18.55 8.5 18V14C8.5 13.45 8.05 13 7.5 13H4.5C3.95 13 3.5 13.45 3.5 14V18C3.5 18.55 3.95 19 4.5 19ZM10.5 19H13.5C14.05 19 14.5 18.55 14.5 18V14C14.5 13.45 14.05 13 13.5 13H10.5C9.95 13 9.5 13.45 9.5 14V18C9.5 18.55 9.95 19 10.5 19ZM16.5 19H19.5C20.05 19 20.5 18.55 20.5 18V14C20.5 13.45 20.05 13 19.5 13H16.5C15.95 13 15.5 13.45 15.5 14V18C15.5 18.55 15.95 19 16.5 19ZM10.5 12H13.5C14.05 12 14.5 11.55 14.5 11V7C14.5 6.45 14.05 6 13.5 6H10.5C9.95 6 9.5 6.45 9.5 7V11C9.5 11.55 9.95 12 10.5 12ZM15.5 7V11C15.5 11.55 15.95 12 16.5 12H19.5C20.05 12 20.5 11.55 20.5 11V7C20.5 6.45 20.05 6 19.5 6H16.5C15.95 6 15.5 6.45 15.5 7Z" />
                        </svg>
                      </button>
                      <button
                        className="sc-hLclGa bUZmol sc-eDnVMP cZbnq"
                        variant="text"
                        scale="sm"
                        id="clickPoolTableView"
                        onClick={closeGrid}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          color="primary"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                          className={isGrid ? "sc-bcPKhP klDlNp" : "sc-bcPKhP bIlHZF"}
                        >
                          <path d="M4.5 14H6.5C7.05 14 7.5 13.55 7.5 13V11C7.5 10.45 7.05 10 6.5 10H4.5C3.95 10 3.5 10.45 3.5 11V13C3.5 13.55 3.95 14 4.5 14ZM4.5 19H6.5C7.05 19 7.5 18.55 7.5 18V16C7.5 15.45 7.05 15 6.5 15H4.5C3.95 15 3.5 15.45 3.5 16V18C3.5 18.55 3.95 19 4.5 19ZM4.5 9H6.5C7.05 9 7.5 8.55 7.5 8V6C7.5 5.45 7.05 5 6.5 5H4.5C3.95 5 3.5 5.45 3.5 6V8C3.5 8.55 3.95 9 4.5 9ZM9.5 14H19.5C20.05 14 20.5 13.55 20.5 13V11C20.5 10.45 20.05 10 19.5 10H9.5C8.95 10 8.5 10.45 8.5 11V13C8.5 13.55 8.95 14 9.5 14ZM9.5 19H19.5C20.05 19 20.5 18.55 20.5 18V16C20.5 15.45 20.05 15 19.5 15H9.5C8.95 15 8.5 15.45 8.5 16V18C8.5 18.55 8.95 19 9.5 19ZM8.5 6V8C8.5 8.55 8.95 9 9.5 9H19.5C20.05 9 20.5 8.55 20.5 8V6C20.5 5.45 20.05 5 19.5 5H9.5C8.95 5 8.5 5.45 8.5 6Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="sc-ceoTrm kGfzRc">
                    <div className="sc-ibUkTT jeszik">
                      <div className="sc-wQgoG hlDcnH">
                        <input
                          placeholder="Search Pools"
                          className="sc-ksJisA sc-epnIpn dcPxF eIKFef"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-cOgmQm jgfRXI">
                  <div id="pools-table" role="table" className="sc-gwhRke bSJTSi">
                    <div className="sc-iLLEsT hrUTYi">
                      <button variant="text" scale="md" className="sc-hLclGa jMKayp">
                        To Top
                        <svg
                          viewBox="0 0 24 24"
                          color="primary"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                          className="sc-bcPKhP bIlHZF"
                        >
                          <path d="M8.11997 14.7101L12 10.8301L15.88 14.7101C16.27 15.1001 16.9 15.1001 17.29 14.7101C17.68 14.3201 17.68 13.6901 17.29 13.3001L12.7 8.7101C12.31 8.3201 11.68 8.3201 11.29 8.7101L6.69997 13.3001C6.30997 13.6901 6.30997 14.3201 6.69997 14.7101C7.08997 15.0901 7.72997 15.1001 8.11997 14.7101Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer walletAction={walletAction} />
    </>
  )
}
