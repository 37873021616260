import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Footer from '../components/Footer.js';
import Navbar from '../components/Navbar';
import Tokenlist from '../components/Tokenlist.js';
import toks from '../data/tokens.json';

export default function Home({ walletAction, isConnected, isLoading }) {
    const [isChartsOpen, setIsChartsOpen] = useState(false)
    const [isTokensOpen, setIsTokensOpen] = useState(false)
    const [tokens, setTokens] = useState(toks)
    const [upSelected, setUpSelected] = useState(toks[0])
    const [downSelected, setDownSelected] = useState(toks[5])


    const swapSelected = () => {
        const up = { ...upSelected }
        const down = { ...downSelected }

        setUpSelected(down);
        setDownSelected(up)
    }

    const setSelected = (selected, isUp) => isUp ? setUpSelected(selected) : setDownSelected(selected)

    const toggleCharts = () => isChartsOpen ? closeCharts() : openCharts()

    const closeCharts = () => setIsChartsOpen(false)

    const openCharts = () => setIsChartsOpen(true)

    const toggleTokens = () => isTokensOpen ? closeTokens() : openTokens()

    const closeTokens = () => setIsTokensOpen(false)

    const openTokens = (dir = true) => setIsTokensOpen(dir)

    return (
        <>
            {isTokensOpen ? <Tokenlist isUp={isTokensOpen !== "down"} setSelected={setSelected} close={closeTokens} tokens={tokens} upSelected={upSelected} downSelected={downSelected} /> : null}
            <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
                <Navbar walletAction={walletAction} />
                <div className="sc-gKHVLF sc-iBAaJG UlmxL bFuSQM">
                    <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr gDrmVh eiQbuK dKnWPh">
                        <div className="sc-gKHVLF fHszqz">
                            <div className="sc-grFOgM dkUmA-D">
                                <Link to="/" className="sc-ZPwkx hrbPHn">
                                    Exchange
                                </Link>
                            </div>
                        </div>
                        <div className="sc-gKHVLF fHszqz">
                            <div className="sc-grFOgM gZNJiV">
                                <Link to="/liquidity" className="sc-ZPwkx dQBfPj">
                                    Liquidity
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-gKHVLF sc-ehwEHI gWzXuy ggQwmN">
                    <div className="sc-djLfiL hMZhUc">
                        <div className="sc-eVRJAz uaifU">
                            <div className="sc-gKHVLF sc-iBAaJG dXMUao dYWiBt">
                                {isChartsOpen ? <div className="sc-gKHVLF sc-eYUiRx bbVhpn cDfsBL show-576">
                                    <div className="sc-gKHVLF sc-iBAaJG hLWvuQ gGFeQF">
                                        <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                                            <div className="sc-cuGlHX devPIG">
                                                <div className="sc-fTkJPW hMWvue">
                                                    <img
                                                        src='https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png'
                                                        className="sc-lirSmk ivSVpp"
                                                        style={{
                                                            marginRight: 4,
                                                            backgroundBlendMode: 'normal!important',
                                                            backgroundClip: 'content-box!important',
                                                            backgroundPosition: '50% 50%!important',
                                                            backgroundColor: 'rgba(0,0,0,0)!important',
                                                            backgroundImage: 'var(--sf-img-12)!important',
                                                            backgroundSize: '100% 100%!important',
                                                            backgroundOrigin: 'content-box!important',
                                                            backgroundRepeat: 'no-repeat!important',
                                                        }}
                                                        alt='PulseX'
                                                    />
                                                </div>
                                                <div sizeraw={24} className="sc-bTBVlW kSXVUo">
                                                    <img
                                                        className="sc-hJNuel emIXL"
                                                        alt="PLSX logo"
                                                        src='https://tokens.app.pulsex.com/images/tokens/0x95B303987A60C71504D99Aa1b13B4DA07b0790ab.png'
                                                        style={{
                                                            backgroundBlendMode: 'normal!important',
                                                            backgroundClip: 'content-box!important',
                                                            backgroundPosition: '50% 50%!important',
                                                            backgroundColor: 'rgba(0,0,0,0)!important',
                                                            backgroundImage: 'var(--sf-img-13)!important',
                                                            backgroundSize: '100% 100%!important',
                                                            backgroundOrigin: 'content-box!important',
                                                            backgroundRepeat: 'no-repeat!important',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sc-grXZZQ jftdDy">PLS/PLSX</div>
                                            <button
                                                className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                                                variant="text"
                                                scale="md"
                                            >
                                                <svg
                                                    viewBox="0 0 24 25"
                                                    ml="6px"
                                                    color="primary"
                                                    width="20px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP hSJyNW"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M18.86 4.86003L21.65 7.65003C21.84 7.84003 21.84 8.16003 21.64 8.35003L18.85 11.14C18.54 11.46 18 11.24 18 10.79V9.00003H4C3.45 9.00003 3 8.55003 3 8.00003C3 7.45003 3.45 7.00003 4 7.00003H18V5.21003C18 4.76003 18.54 4.54003 18.86 4.86003ZM5.14001 19.14L2.35001 16.35C2.16001 16.16 2.16001 15.84 2.36001 15.65L5.15001 12.86C5.46001 12.54 6.00001 12.76 6.00001 13.21V15H20C20.55 15 21 15.45 21 16C21 16.55 20.55 17 20 17H6.00001V18.79C6.00001 19.24 5.46001 19.46 5.14001 19.14Z"
                                                    />
                                                </svg>
                                            </button>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd" onClick={closeCharts}>
                                                <button
                                                    className="sc-hLclGa kiKWMP sc-hhYtLa bGIJRi"
                                                    aria-label="Basic"
                                                    title="Basic"
                                                    scale="sm"
                                                    variant="text"
                                                    color="primary"
                                                    mr="8px"
                                                >
                                                    Basic
                                                </button>
                                            </div>
                                        </div>
                                        <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                            <button
                                                className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                                                variant="text"
                                                scale="md"
                                            >
                                                <svg
                                                    viewBox="0 0 25 24"
                                                    color="text"
                                                    width="20px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP cvmRst"
                                                >
                                                    <path d="M20.497 4.6l-.004-.028-.006-.036-.008-.033-.007-.03-.012-.032-.01-.03-.014-.028-.014-.03-.016-.027-.018-.03-.021-.028-.018-.024a.663.663 0 00-.044-.048v-.001a.66.66 0 00-.049-.044l-.024-.018-.028-.02-.03-.018-.026-.016-.03-.015c-.01-.004-.02-.01-.03-.013l-.03-.011-.03-.011-.031-.008-.033-.008-.035-.005-.03-.005A.684.684 0 0019.834 4h-4.667a.667.667 0 100 1.333h3.057l-4.862 4.862a.667.667 0 10.943.943l4.862-4.862v3.057a.667.667 0 101.333 0V4.667a.658.658 0 00-.003-.066zM10.696 12.861l-4.862 4.862v-3.057a.667.667 0 10-1.333 0v4.667l.003.066.005.029.005.035.008.033.008.03.01.032.012.03.013.029.015.03.016.027.017.029.021.028.018.024a.663.663 0 00.093.093l.024.018.029.021.029.018.026.016.03.014.03.014.03.01.031.012.03.007.033.008.036.006.029.004a.657.657 0 00.066.003h4.666a.667.667 0 000-1.333H6.777l4.862-4.862a.667.667 0 00-.943-.943z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="sc-gKHVLF sc-iBAaJG hLWvuQ cefqgq">
                                        <div className="sc-gKHVLF sc-iBAaJG bXWdbi izyNuh">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL bTLWOg">
                                                <div className="sc-grXZZQ cawQhb">3.71</div>
                                                <div className="sc-grXZZQ iEUSFZ">PLS/PLSX</div>
                                                <div className="sc-grXZZQ gqLOtI">+0.026 (0.72%)</div>
                                            </div>
                                            <div className="sc-grXZZQ jcOPsg">{new Date().toUTCString()}</div>
                                        </div>
                                        <div className="sc-gKHVLF UlmxL">
                                            <div variant="primary" className="sc-bBbNsw toFjs">
                                                <button variant="primary" scale="sm" className="sc-hLclGa deXseV">
                                                    24H
                                                </button>
                                                <button
                                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                                    variant="primary"
                                                    scale="sm"
                                                >
                                                    1W
                                                </button>
                                                <button
                                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                                    variant="primary"
                                                    scale="sm"
                                                >
                                                    1M
                                                </button>
                                                <button
                                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                                    variant="primary"
                                                    scale="sm"
                                                >
                                                    1Y
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sc-gKHVLF fqMyft">
                                        <div
                                            className="recharts-responsive-container"
                                            style={{ width: '100%', height: '100%', minWidth: 0 }}
                                        >
                                            <div
                                                className="recharts-wrapper"
                                                role="region"
                                                style={{
                                                    position: 'relative',
                                                    cursor: 'default',
                                                    width: '100%',
                                                    height: '100%',
                                                    maxHeight: 346,
                                                    maxWidth: 1216,
                                                }}
                                            >
                                                <svg
                                                    className="recharts-surface"
                                                    width={1216}
                                                    height={346}
                                                    viewBox="0 0 1216 346"
                                                    style={{ width: '100%', height: '100%' }}
                                                >
                                                    <defs>
                                                        <clipPath id="recharts11-clip">
                                                            <rect x={0} y={5} height={306} width={1216} />
                                                        </clipPath>
                                                    </defs>
                                                    <defs>
                                                        <linearGradient id="gradient" x1={0} y1={0} x2={0} y2={1}>
                                                            <stop offset="5%" stopColor="#33ff77" stopOpacity="0.34" />
                                                            <stop offset="100%" stopColor="#00802b" stopOpacity={0} />
                                                        </linearGradient>
                                                    </defs>
                                                    <g className="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis">
                                                        <g className="recharts-cartesian-axis-ticks">
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="50.666666666666664"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="50.666666666666664" dy="0.71em">
                                                                        02:00 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x={152}
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x={152} dy="0.71em">
                                                                        04:00 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="253.33333333333331"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="253.33333333333331" dy="0.71em">
                                                                        06:00 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="354.66666666666663"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="354.66666666666663" dy="0.71em">
                                                                        08:00 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x={456}
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x={456} dy="0.71em">
                                                                        10:00 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="557.3333333333333"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="557.3333333333333" dy="0.71em">
                                                                        12:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="658.6666666666666"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="658.6666666666666" dy="0.71em">
                                                                        02:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x={760}
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x={760} dy="0.71em">
                                                                        04:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="861.3333333333333"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="861.3333333333333" dy="0.71em">
                                                                        06:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="962.6666666666666"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="962.6666666666666" dy="0.71em">
                                                                        08:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x={1064}
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x={1064} dy="0.71em">
                                                                        10:00 AM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                                <text
                                                                    orientation="bottom"
                                                                    width={1216}
                                                                    height={30}
                                                                    stroke="none"
                                                                    x="1182.08203125"
                                                                    y={319}
                                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                                    textAnchor="middle"
                                                                    fill="#666"
                                                                >
                                                                    <tspan x="1182.08203125" dy="0.71em">
                                                                        01:07 PM
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g className="recharts-layer recharts-area">
                                                        <g className="recharts-layer">
                                                            <path
                                                                fill="url(#gradient)"
                                                                strokeWidth={2}
                                                                fillOpacity="0.6"
                                                                width={1216}
                                                                height={306}
                                                                stroke="none"
                                                                className="recharts-curve recharts-area-area"
                                                                d="M0,284.275L50.667,271.809L101.333,220.246L152,252.544L202.667,275.81L253.333,280.677L304,301.559L354.667,286.884L405.333,309.122L456,266.73L506.667,259.874L557.333,169.397L608,200.034L658.667,117.882L709.333,168.147L760,155.246L810.667,66.23L861.333,53.498L912,84.225L962.667,120.891L1013.333,122.54L1064,130.782L1114.667,105.454L1165.333,111.128L1216,183.016L1216,311L1165.333,311L1114.667,311L1064,311L1013.333,311L962.667,311L912,311L861.333,311L810.667,311L760,311L709.333,311L658.667,311L608,311L557.333,311L506.667,311L456,311L405.333,311L354.667,311L304,311L253.333,311L202.667,311L152,311L101.333,311L50.667,311L0,311Z"
                                                            />
                                                            <path
                                                                stroke="#00e64d"
                                                                fill="none"
                                                                strokeWidth={2}
                                                                fillOpacity="0.6"
                                                                width={1216}
                                                                height={306}
                                                                className="recharts-curve recharts-area-curve"
                                                                d="M0,284.275L50.667,271.809L101.333,220.246L152,252.544L202.667,275.81L253.333,280.677L304,301.559L354.667,286.884L405.333,309.122L456,266.73L506.667,259.874L557.333,169.397L608,200.034L658.667,117.882L709.333,168.147L760,155.246L810.667,66.23L861.333,53.498L912,84.225L962.667,120.891L1013.333,122.54L1064,130.782L1114.667,105.454L1165.333,111.128L1216,183.016"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div
                                                    tabIndex={-1}
                                                    role="dialog"
                                                    className="recharts-tooltip-wrapper sf-hidden"
                                                    style={{
                                                        visibility: 'hidden',
                                                        pointerEvents: 'none',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                <div className="sc-gKHVLF sc-iBAaJG sc-fezMU UlmxL lbwIxd eAeCUe">
                                    <div className="sc-gKHVLF sc-iDCbHt UlmxL eKfSSj">
                                        <div className="sc-bYUneI fbWnpo sc-ihxOxR iRZkqd">
                                            <div className="sc-gKHVLF sc-kMbQoj UlmxL dywlDY">
                                                <div className="sc-gKHVLF sc-iBAaJG sc-cixUIr UlmxL lbwIxd kITGvr">
                                                    <div className="sc-gKHVLF sc-iBAaJG bTyHhM feFGPx">
                                                        <div className="sc-gKHVLF sc-iBAaJG jQVJvO izyNuh">
                                                            <div className="sc-grXZZQ CdzCT">Swap</div>
                                                        </div>
                                                        <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                            <button
                                                                className="sc-hLclGa bUZmol sc-eDnVMP sc-kxSiKH cZbnq cJvRrC"
                                                                variant="text"
                                                                scale="sm"
                                                                onClick={toggleCharts}
                                                            >
                                                                <svg
                                                                    viewBox="0 0 18 18"
                                                                    width="18px"
                                                                    color="textSubtle"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className={isChartsOpen ? "sc-bcPKhP bIlHZF" : "sc-bcPKhP Eouil"}
                                                                >
                                                                    <path d="m4.4222 0c-2.5517 0-4.4222 2.4246-4.4222 5.1795v7.641c0 2.7548 1.8705 5.1795 4.4222 5.1795h9.1556c2.5517 0 4.4222-2.4246 4.4222-5.1795v-7.641c0-2.7548-1.8705-5.1795-4.4222-5.1795zm0 2.0037h9.1556c1.2382 0 2.4185 1.3013 2.4185 3.1758v7.641c0 1.8744-1.1804 3.1758-2.4185 3.1758h-9.1556c-1.2382 0-2.4185-1.3013-2.4185-3.1758v-7.641c0-1.8744 1.1804-3.1758 2.4185-3.1758zm9.9676 3.1366a1.0018 1.0018 0 0 0-0.69855 0.31699l-3.6023 3.845-1.988-2.0252a1.0019 1.0019 0 0 0-1.4304 0l-3.8078 3.8802a1.0018 1.0018 0 0 0 0.013697 1.4167 1.0018 1.0018 0 0 0 1.4167-0.0137l3.0936-3.1523 2.0037 2.0428a1.0019 1.0019 0 0 0 1.446-0.01566l4.3166-4.6081a1.0018 1.0018 0 0 0-0.045-1.4167 1.0018 1.0018 0 0 0-0.71812-0.27003z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id="swap-page"
                                                    className="sc-gKHVLF sc-iBAaJG sc-eramgD UlmxL lbwIxd vmSTT"
                                                    style={{ minHeight: 200 }}
                                                >
                                                    <div gap="sm" className="sc-gVQcLu cZNuEq">
                                                        <div
                                                            id="swap-currency-input"
                                                            className="sc-gKHVLF UlmxL"
                                                        >
                                                            <div className="sc-eJmhDG lhZUsi">
                                                                <div className="sc-fFJcZz iXEjtC">
                                                                    <div className="sc-gIvWvA iHXtSR">
                                                                        <input
                                                                            placeholder={0.0}
                                                                            className="sc-isKtiH jdbMyp"
                                                                            type="text"
                                                                            inputMode="numeric"
                                                                        />
                                                                        <button
                                                                            className="sc-hLclGa bUZmol sc-kATywh lppJEz open-currency-select-button"
                                                                            variant="text"
                                                                            scale="sm"
                                                                            onClick={openTokens}
                                                                        >
                                                                            <img
                                                                                src={upSelected.img}
                                                                                className="sc-lirSmk ilQYRV"
                                                                                style={{ marginRight: 8 }}
                                                                            />
                                                                            <div id="pair" className="sc-grXZZQ ciLqzo">
                                                                                {upSelected.symbol}
                                                                            </div>
                                                                            <svg
                                                                                viewBox="0 0 24 24"
                                                                                color="text"
                                                                                width="20px"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="sc-bcPKhP cvmRst"
                                                                            >
                                                                                <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                    <div className="sc-eAmRia igErbW">
                                                                        <div className="sc-grXZZQ cdHhbk" />
                                                                        <div className="sc-gKHVLF sc-iBAaJG bFkCsk kDlpqT">
                                                                            {isConnected ? <div
                                                                                className="sc-grXZZQ dGxDRB"
                                                                                style={{ display: 'inline', cursor: 'pointer' }}
                                                                            >
                                                                                Balance: {upSelected.balance}
                                                                            </div> : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sc-gKHVLF sc-iuCANo sc-hFHwku hGSoDR kGvWlg bnxMzz">
                                                            <button
                                                                className="sc-hLclGa eGSpVG sc-eDnVMP sc-cmgcLV cZbnq dgTUMP"
                                                                variant="light"
                                                                scale="sm"
                                                                onClick={swapSelected}
                                                            >
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    className="sc-bcPKhP bIlHZF icon-down"
                                                                    color="primary"
                                                                    width="20px"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    {' '}
                                                                    <path d="M11 5V16.17L6.11997 11.29C5.72997 10.9 5.08997 10.9 4.69997 11.29C4.30997 11.68 4.30997 12.31 4.69997 12.7L11.29 19.29C11.68 19.68 12.31 19.68 12.7 19.29L19.29 12.7C19.68 12.31 19.68 11.68 19.29 11.29C18.9 10.9 18.27 10.9 17.88 11.29L13 16.17V5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5Z"></path>
                                                                </svg>
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    className="sc-bcPKhP bIlHZF icon-up-down"
                                                                    color="primary"
                                                                    width="20px"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    {' '}
                                                                    <path d="M16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16zM8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35a.501.501 0 00-.7 0z"></path>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div
                                                            id="swap-currency-output"
                                                            className="sc-gKHVLF UlmxL"
                                                        >
                                                            <div className="sc-eJmhDG lhZUsi">
                                                                <div className="sc-fFJcZz iXEjtC">
                                                                    <div className="sc-gIvWvA iHXtSR">
                                                                        <input
                                                                            placeholder={0.0}
                                                                            className="sc-isKtiH jdbMyp"
                                                                            type="text"
                                                                            inputMode="numeric"
                                                                        />
                                                                        <button
                                                                            className="sc-hLclGa bUZmol sc-kATywh lppJEz open-currency-select-button"
                                                                            variant="text"
                                                                            scale="sm"
                                                                            onClick={() => openTokens("down")}
                                                                        >
                                                                            <img
                                                                                className="sc-hJNuel oqEAr"
                                                                                alt="PLSX logo"
                                                                                src={downSelected.img}
                                                                                style={{ marginRight: 8 }}
                                                                            />
                                                                            <div id="pair" className="sc-grXZZQ ciLqzo">
                                                                                {downSelected.symbol}
                                                                            </div>
                                                                            <svg
                                                                                viewBox="0 0 24 24"
                                                                                color="text"
                                                                                width="20px"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="sc-bcPKhP cvmRst"
                                                                            >
                                                                                <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                                                                            </svg>
                                                                        </button>
                                                                        <div
                                                                            className="sc-gKHVLF sc-iBAaJG iXxQFy iIFIZh"
                                                                            style={{ gap: 4 }}
                                                                        >
                                                                            <div>
                                                                                <button
                                                                                    className="sc-hLclGa bUZmol sc-eDnVMP cZbnq"
                                                                                    scale="sm"
                                                                                    variant="text"
                                                                                    style={{
                                                                                        width: 'auto',
                                                                                        position: 'relative',
                                                                                    }}
                                                                                >
                                                                                    <svg
                                                                                        viewBox="0 0 24 24"
                                                                                        color="textSubtle"
                                                                                        width="16px"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className="sc-bcPKhP Eouil"
                                                                                    >
                                                                                        <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sc-eAmRia igErbW">
                                                                        <div className="sc-grXZZQ cdHhbk" />
                                                                        <div className="sc-gKHVLF sc-iBAaJG bFkCsk kDlpqT">
                                                                            {isConnected ? <div
                                                                                className="sc-grXZZQ dGxDRB"
                                                                                style={{ display: 'inline', cursor: 'pointer' }}
                                                                            >
                                                                                Balance: {downSelected.balance}
                                                                            </div> : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sc-gKHVLF gHTCId">
                                                         <button
                                                            width="100%"
                                                            variant="primary"
                                                            scale="md"
                                                            className="sc-hLclGa jmRqFu"
                                                            onClick={walletAction}
                                                            disabled={isLoading ? true : false}
                                                        >
                                                            {isLoading ? "Loading..." : !isConnected ? "Connect Wallet" : "Sign In"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL kAtWFC" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer walletAction={walletAction} />
            {isChartsOpen ? <div className="sc-ezGUZh jrQXsw hidden-576">
                <div className="sc-gKHVLF lmYwkI">
                    <button
                        className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                        variant="text"
                        scale="md"
                        onClick={closeCharts}
                    >
                        <svg
                            viewBox="0 0 24 24"
                            color="text"
                            width="20px"
                            xmlns="http://www.w3.org/2000/svg"
                            className="sc-bcPKhP cvmRst"
                        >
                            <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
                        </svg>
                    </button>
                </div>
                <div className="sc-gKHVLF sc-eYUiRx bbVhpn cDfsBL">
                    <div className="sc-gKHVLF sc-iBAaJG hLWvuQ gGFeQF">
                        <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh">
                            <div className="sc-cuGlHX devPIG">
                                <div className="sc-fTkJPW hMWvue">
                                    <img
                                        src='https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png'
                                        className="sc-lirSmk ivSVpp"
                                        style={{
                                            marginRight: 4,
                                            backgroundBlendMode: 'normal!important',
                                            backgroundClip: 'content-box!important',
                                            backgroundPosition: '50% 50%!important',
                                            backgroundColor: 'rgba(0,0,0,0)!important',
                                            backgroundImage: 'var(--sf-img-12)!important',
                                            backgroundSize: '100% 100%!important',
                                            backgroundOrigin: 'content-box!important',
                                            backgroundRepeat: 'no-repeat!important',
                                        }}
                                        alt='PulseX'
                                    />
                                </div>
                                <div sizeraw={24} className="sc-bTBVlW kSXVUo">
                                    <img
                                        className="sc-hJNuel emIXL"
                                        alt="PLSX logo"
                                        src='https://tokens.app.pulsex.com/images/tokens/0x95B303987A60C71504D99Aa1b13B4DA07b0790ab.png'
                                        style={{
                                            backgroundBlendMode: 'normal!important',
                                            backgroundClip: 'content-box!important',
                                            backgroundPosition: '50% 50%!important',
                                            backgroundColor: 'rgba(0,0,0,0)!important',
                                            backgroundImage: 'var(--sf-img-13)!important',
                                            backgroundSize: '100% 100%!important',
                                            backgroundOrigin: 'content-box!important',
                                            backgroundRepeat: 'no-repeat!important',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="sc-grXZZQ jftdDy">PLS/PLSX</div>
                            <button
                                className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                                variant="text"
                                scale="md"
                            >
                                <svg
                                    viewBox="0 0 24 25"
                                    ml="6px"
                                    color="primary"
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sc-bcPKhP hSJyNW"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.86 4.86003L21.65 7.65003C21.84 7.84003 21.84 8.16003 21.64 8.35003L18.85 11.14C18.54 11.46 18 11.24 18 10.79V9.00003H4C3.45 9.00003 3 8.55003 3 8.00003C3 7.45003 3.45 7.00003 4 7.00003H18V5.21003C18 4.76003 18.54 4.54003 18.86 4.86003ZM5.14001 19.14L2.35001 16.35C2.16001 16.16 2.16001 15.84 2.36001 15.65L5.15001 12.86C5.46001 12.54 6.00001 12.76 6.00001 13.21V15H20C20.55 15 21 15.45 21 16C21 16.55 20.55 17 20 17H6.00001V18.79C6.00001 19.24 5.46001 19.46 5.14001 19.14Z"
                                    />
                                </svg>
                            </button>
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                <button
                                    className="sc-hLclGa kiKWMP sc-hhYtLa bGIJRi"
                                    aria-label="Basic"
                                    title="Basic"
                                    scale="sm"
                                    variant="text"
                                    color="primary"
                                    mr="8px"
                                    onClick={closeCharts}
                                >
                                    <svg
                                        viewBox="0 0 18 18"
                                        color="primary"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="sc-bcPKhP bIlHZF"
                                    >
                                        <path d="m4.4222 0c-2.5517 0-4.4222 2.4246-4.4222 5.1795v7.641c0 2.7548 1.8705 5.1795 4.4222 5.1795h9.1556c2.5517 0 4.4222-2.4246 4.4222-5.1795v-7.641c0-2.7548-1.8705-5.1795-4.4222-5.1795zm0 2.0037h9.1556c1.2382 0 2.4185 1.3013 2.4185 3.1758v7.641c0 1.8744-1.1804 3.1758-2.4185 3.1758h-9.1556c-1.2382 0-2.4185-1.3013-2.4185-3.1758v-7.641c0-1.8744 1.1804-3.1758 2.4185-3.1758zm9.9676 3.1366a1.0018 1.0018 0 0 0-0.69855 0.31699l-3.6023 3.845-1.988-2.0252a1.0019 1.0019 0 0 0-1.4304 0l-3.8078 3.8802a1.0018 1.0018 0 0 0 0.013697 1.4167 1.0018 1.0018 0 0 0 1.4167-0.0137l3.0936-3.1523 2.0037 2.0428a1.0019 1.0019 0 0 0 1.446-0.01566l4.3166-4.6081a1.0018 1.0018 0 0 0-0.045-1.4167 1.0018 1.0018 0 0 0-0.71812-0.27003z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sc-gKHVLF sc-iBAaJG hLWvuQ cefqgq">
                        <div className="sc-gKHVLF sc-iBAaJG bXWdbi izyNuh">
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL bTLWOg">
                                <div className="sc-grXZZQ cawQhb">3.71</div>
                                <div className="sc-grXZZQ iEUSFZ">PLS/PLSX</div>
                                <div className="sc-grXZZQ gqLOtI">+0.027 (0.72%)</div>
                            </div>
                            <div className="sc-grXZZQ jcOPsg">{new Date().toUTCString()}</div>
                        </div>
                        <div className="sc-gKHVLF UlmxL">
                            <div variant="primary" className="sc-bBbNsw toFjs">
                                <button variant="primary" scale="sm" className="sc-hLclGa deXseV">
                                    24H
                                </button>
                                <button
                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                    variant="primary"
                                    scale="sm"
                                >
                                    1W
                                </button>
                                <button
                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                    variant="primary"
                                    scale="sm"
                                >
                                    1M
                                </button>
                                <button
                                    className="sc-hLclGa deXseV sc-ivDvhZ kgBxRX"
                                    variant="primary"
                                    scale="sm"
                                >
                                    1Y
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sc-gKHVLF eRpMrm">
                        <div
                            className="recharts-responsive-container"
                            style={{ width: '100%', height: '100%', minWidth: 0 }}
                        >
                            <div
                                className="recharts-wrapper"
                                role="region"
                                style={{
                                    position: 'relative',
                                    cursor: 'default',
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: 796,
                                    maxWidth: 832,
                                }}
                            >
                                <svg
                                    className="recharts-surface"
                                    width={832}
                                    height={796}
                                    viewBox="0 0 832 796"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <defs>
                                        <clipPath id="recharts218-clip">
                                            <rect x={0} y={5} height={756} width={832} />
                                        </clipPath>
                                    </defs>
                                    <defs>
                                        <linearGradient id="gradient" x1={0} y1={0} x2={0} y2={1}>
                                            <stop offset="5%" stopColor="#33ff77" stopOpacity="0.34" />
                                            <stop offset="100%" stopColor="#00802b" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <g className="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis">
                                        <g className="recharts-cartesian-axis-ticks">
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="69.33333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="69.33333333333333" dy="0.71em">
                                                        03:00 PM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="173.33333333333331"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="173.33333333333331" dy="0.71em">
                                                        06:00 PM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="277.3333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="277.3333333333333" dy="0.71em">
                                                        09:00 PM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="381.3333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="381.3333333333333" dy="0.71em">
                                                        12:00 AM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="485.3333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="485.3333333333333" dy="0.71em">
                                                        03:00 AM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="589.3333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="589.3333333333333" dy="0.71em">
                                                        06:00 AM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="693.3333333333333"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="693.3333333333333" dy="0.71em">
                                                        09:00 AM
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g className="recharts-layer recharts-cartesian-axis-tick">
                                                <text
                                                    orientation="bottom"
                                                    width={832}
                                                    height={30}
                                                    stroke="none"
                                                    x="798.08203125"
                                                    y={769}
                                                    className="recharts-text recharts-cartesian-axis-tick-value"
                                                    textAnchor="middle"
                                                    fill="#666"
                                                >
                                                    <tspan x="798.08203125" dy="0.71em">
                                                        01:28 PM
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g className="recharts-layer recharts-area">
                                        <g className="recharts-layer">
                                            <path
                                                fill="url(#gradient)"
                                                strokeWidth={2}
                                                fillOpacity="0.6"
                                                width={832}
                                                height={756}
                                                stroke="none"
                                                className="recharts-curve recharts-area-area"
                                                d="M0,694.974L34.667,664.176L69.333,536.783L104,616.579L138.667,674.06L173.333,686.085L208,737.676L242.667,701.418L277.333,756.36L312,651.626L346.667,634.689L381.333,411.158L416,486.848L450.667,283.885L485.333,408.069L520,376.195L554.667,156.275L589.333,124.819L624,200.732L658.667,291.318L693.333,295.392L728,315.754L762.667,253.181L797.333,267.198L832,443.764L832,761L797.333,761L762.667,761L728,761L693.333,761L658.667,761L624,761L589.333,761L554.667,761L520,761L485.333,761L450.667,761L416,761L381.333,761L346.667,761L312,761L277.333,761L242.667,761L208,761L173.333,761L138.667,761L104,761L69.333,761L34.667,761L0,761Z"
                                            />
                                            <path
                                                stroke="#00e64d"
                                                fill="none"
                                                strokeWidth={2}
                                                fillOpacity="0.6"
                                                width={832}
                                                height={756}
                                                className="recharts-curve recharts-area-curve"
                                                d="M0,694.974L34.667,664.176L69.333,536.783L104,616.579L138.667,674.06L173.333,686.085L208,737.676L242.667,701.418L277.333,756.36L312,651.626L346.667,634.689L381.333,411.158L416,486.848L450.667,283.885L485.333,408.069L520,376.195L554.667,156.275L589.333,124.819L624,200.732L658.667,291.318L693.333,295.392L728,315.754L762.667,253.181L797.333,267.198L832,443.764"
                                            />
                                        </g>
                                    </g>
                                </svg>
                                <div
                                    tabIndex={-1}
                                    role="dialog"
                                    className="recharts-tooltip-wrapper sf-hidden"
                                    style={{
                                        visibility: 'hidden',
                                        pointerEvents: 'none',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
    )
}
