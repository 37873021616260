const ankrKey = process.env.REACT_APP_ANKR_KEY;

export const arbitrum = {
  chainId: 42161,
  name: "Arbitrum One",
  currency: "ETH",
  explorerUrl: "https://arbiscan.io",
  rpcUrl: `https://rpc.ankr.com/arbitrum/${ankrKey}`,
};

export const avalanche = {
  chainId: 43114,
  name: "Avalanche",
  currency: "AVAX",
  explorerUrl: "https://snowtrace.io",
  rpcUrl: `https://rpc.ankr.com/avalanche/${ankrKey}`,
};

export const base = {
  chainId: 8453,
  name: "Base",
  currency: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: `https://rpc.ankr.com/base/${ankrKey}`,
};

export const bsc = {
  chainId: 56,
  name: "BNB Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: `https://rpc.ankr.com/bsc/${ankrKey}`,
};

export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  //rpcUrl: 'https://cloudflare-eth.com',
  rpcUrl: `https://rpc.ankr.com/eth/${ankrKey}`,
};

export const optimism = {
  chainId: 10,
  name: "Optimism",
  currency: "ETH",
  explorerUrl: "https://optimistic.etherscan.io",
  rpcUrl: `https://rpc.ankr.com/optimism/${ankrKey}`,
};

export const polygon = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: `https://rpc.ankr.com/polygon/${ankrKey}`,
};

export const pulsechain = {
  chainId: 369,
  name: "PulseChain",
  currency: "PLS",
  explorerUrl: "https://scan.pulsechain.com",
  rpcUrl: `https://rpc.pulsechain.com`,
};

export const linea = {
  chainId: 59144,
  name: "Linea",
  currency: "ETH",
  explorerUrl: "https://lineascan.build",
  rpcUrl: `https://rpc.ankr.com/linea/${ankrKey}`,
};

export const blast = {
  chainId: 81457,
  name: "Blast",
  currency: "ETH",
  explorerUrl: "https://blastscan.io",
  rpcUrl: `https://rpc.ankr.com/blast/${ankrKey}`,
};

export const mode = {
  chainId: 34443,
  name: "Mode",
  currency: "ETH",
  explorerUrl: "https://explorer.mode.network",
  rpcUrl: `https://mainnet.mode.network`,
};

/*export const zkSync = {
  chainId: 324,
  name: "zkSync Era",
  currency: "ETH",
  explorerUrl: "https://explorer.zksync.io",
  rpcUrl: `https://rpc.ankr.com/zksync_era/${ankrKey}`,
};

export const moonbeam = {
  chainId: 1284,
  name: "Moonbeam",
  currency: "GLMR",
  explorerUrl: "https://moonscan.io",
  rpcUrl: `https://rpc.ankr.com/moonbeam/${ankrKey}`,
};

export const fantom = {
  chainId: 250,
  name: "Fantom",
  currency: "FTM",
  explorerUrl: "https://ftmscan.com",
  rpcUrl: `https://rpc.ankr.com/fantom/${ankrKey}`,
};*/

export const chains = {
  42161: {
      dbankId: 'arb',
      name: 'Arbitrum One',
      network: 'arbitrum',
      currency: 'ETH',
      explorerUrl: 'https://arbiscan.io/',
      rpcUrl: `https://rpc.ankr.com/arbitrum/${ankrKey}`,
    },
  43114: {
      dbankId: 'avax',
      name: 'Avalanche',
      network: 'avalanche',
      currency: 'AVAX',
      explorerUrl: 'https://snowtrace.io/',
      rpcUrl: `https://rpc.ankr.com/avalanche/${ankrKey}`,
    },
  8453: {
      dbankId: 'base',
      name: 'Base',
      network: 'base',
      currency: 'ETH',
      explorerUrl: 'https://basescan.org/',
      rpcUrl: `https://rpc.ankr.com/base/${ankrKey}`,
      },
  56: {
      dbankId: 'bsc',
      name: 'BNB Smart Chain',
      network: 'bsc',
      currency: 'AVAX',
      explorerUrl: 'https://bscscan.com/',
      rpcUrl: `https://rpc.ankr.com/bsc/${ankrKey}`,
      },
  1:  {
      dbankId: 'eth',
      name: 'Ethereum',
      network: 'mainnet',
      currency: 'ETH',
      explorerUrl: 'https://etherscan.io/',
      rpcUrl: `https://rpc.ankr.com/eth/${ankrKey}`,
      },
  10: {
      dbankId: 'op',
      name: 'Optimism',
      network: 'optimism',
      currency: 'ETH',
      explorerUrl: 'https://optimistic.etherscan.io/',
      rpcUrl: `https://rpc.ankr.com/optimism/${ankrKey}`,
      },
  137: {
      dbankId: 'matic',
      name: 'Polygon',
      network: 'polygon',
      currency: 'MATIC',
      explorerUrl: 'https://polygonscan.com/',
      rpcUrl: `https://rpc.ankr.com/polygon/${ankrKey}`,
  },
  369: {
      dbankId: 'pls',
      name: 'PulseChain',
      network: 'pulsechain',
      currency: 'PLS',
      explorerUrl: 'https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/',
      rpcUrl: `https://rpc.pulsechain.com`,
      wssUrl: `wss://rpc.pulsechain.com`,
  },
  59144: {
      dbankId: 'linea',
      name: 'Linea',
      network: 'linea',
      currency: 'ETH',
      explorerUrl: 'https://lineascan.build/',
      rpcUrl: `https://rpc.ankr.com/linea/${ankrKey}`,
  },
  81457: {
      dbankId: 'blast',
      name: 'Blast',
      network: 'blast',
      currency: 'ETH',
      explorerUrl: 'https://blastscan.io/',
      rpcUrl: `https://rpc.ankr.com/blast/${ankrKey}`,
  },
  34443: {
      dbankId: 'mode',
      name: 'Mode',
      network: 'mode',
      currency: 'ETH',
      explorerUrl: 'https://explorer.mode.network/',
      rpcUrl: `https://rpc.ankr.com/mode/${ankrKey}`, //confirm!!
  },

  /*324: {
      dbankId: 'era',
      name: 'zkSync Era',
      network: 'zkSync',
      currency: 'ETH',
      explorerUrl: 'https://explorer.zksync.io/',
      rpcUrl: `https://rpc.ankr.com/zksync_era/${ankrKey}` 
  },
  1284: {
      dbankId: 'mobm',
      name: 'Moonbeam',
      network: 'moonbeam',
      currency: 'GLMR',
      explorerUrl: 'https://moonscan.io/',
      rpcUrl: `https://rpc.ankr.com/moonbeam/${ankrKey}`
  },
  250: {
      dbankId: 'ftm',
      name: 'Fantom',
      network: 'fantom',
      currency: 'FTM',
      explorerUrl: 'https://ftmscan.com/',
  }*/
}
