import React from 'react';

import { Link } from 'react-router-dom';

import {
  useDisconnect,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';

export default function Walletmenu({ close, isWalletOpen, openWallet, openTransactions }) {
    const { disconnect } = useDisconnect()
    const { address } = useWeb3ModalAccount()
    return (
        <div role="dialog">
            <div
                className="sc-eGknfI fsqaXe appear"
                style={{ transform: 'translateX(0px)' }}
            >
                <div role="presentation" className="sc-gKHVLF sc-gisvNi UlmxL jALIdj" />
                <div
                    className="sc-jSwlEQ sc-bhFOXJ eYiExZ lpumgW"
                    style={{ overflow: 'visible' }}
                >
                    <div className="sc-gKHVLF HCNtR sc-hGdpZg fjdPiX">
                        <div className="sc-gKHVLF sc-iBAaJG sc-WSdAm sc-comYcR UlmxL lbwIxd mwBGt fPZMxu">
                            <div className="sc-gKHVLF sc-iBAaJG sc-ikzIVP UlmxL lbwIxd iYobww">
                                <h2 className="sc-grXZZQ sc-AOXSc jftdDy eONOKz">Your Wallet</h2>
                            </div>
                            <button
                                className="sc-hLclGa jMKayp sc-eDnVMP cZbrf"
                                variant="text"
                                scale="md"
                                onClick={close}
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    color="text"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sc-bcPKhP cvmRst"
                                >
                                    <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z" />
                                </svg>
                            </button>
                        </div>
                        <div className="sc-bsgKK guhha">
                            <div variant="subtle" className="sc-bBbNsw eatsXv">
                                <button onClick={openWallet} variant="subtle" scale="sm" className={`sc-hLclGa jiLBrK ${isWalletOpen === "tx" ? "sc-ivDvhZ bnecNP" : ""}`}>
                                    Wallet
                                </button>
                                <button
                                    onClick={openTransactions}
                                    className={`sc-hLclGa jiLBrK ${isWalletOpen === "tx" ? "" : "sc-ivDvhZ bnecNP"}`}
                                    variant="subtle"
                                    scale="sm"
                                >
                                    Transactions
                                </button>
                            </div>
                        </div>
                        {isWalletOpen === "tx" ? <div className="sc-gKHVLF sc-iBAaJG sc-tIxES bYpCwe lbwIxd jRRtdU">
                            <div className="sc-gKHVLF fPpGoB">
                                <div className="sc-gKHVLF sc-iBAaJG hDjHqY feFGPx">
                                    <div className="sc-grXZZQ fSPbHy">Recent Transactions</div>
                                </div>
                                <div className="sc-grXZZQ kqfMUH">No recent transactions</div>
                            </div>
                        </div> : <div className="sc-gKHVLF sc-iBAaJG sc-tIxES bYpCwe lbwIxd jRRtdU">
                            <div className="sc-grXZZQ kiTlgj">Your Address</div>
                            <div className="sc-gKHVLF sc-iBAaJG sc-GFXod hDjHqY izyNuh jiYTjh">
                                <div className="sc-gKHVLF cjiOgf">
                                    <div className="sc-gKHVLF sc-iBAaJG sc-bZCZoC UlmxL lbwIxd cnpfXu">
                                        <div
                                            title={address}
                                            className="sc-iUeHef llqloz"
                                        >
                                            <input
                                                type="text"
                                                readOnly
                                                defaultValue={address}
                                            />
                                        </div>
                                        <div className="sc-gKHVLF sc-iBAaJG eTHYuv lbwIxd">
                                            <div>
                                                <button
                                                    className="sc-hLclGa bUZmol sc-eDnVMP cZbnq"
                                                    scale="sm"
                                                    variant="text"
                                                    style={{ width: 'auto', position: 'relative' }}
                                                >
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        color="primary"
                                                        width="24px"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="sc-bcPKhP bIlHZF"
                                                    >
                                                        <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-gKHVLF fxCCop">
                                <div className="sc-gKHVLF sc-iBAaJG ebCCm fTBGgZ">
                                    <div className="sc-gKHVLF sc-iBAaJG NzlMn lbwIxd">
                                        <img
                                            alt="chain-369"
                                            src='https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png'
                                            width={24}
                                            height={24}
                                            style={{
                                                maxHeight: 24,
                                                backgroundBlendMode: 'normal!important',
                                                backgroundClip: 'content-box!important',
                                                backgroundPosition: '50% 50%!important',
                                                backgroundColor: 'rgba(0,0,0,0)!important',
                                                backgroundImage: 'var(--sf-img-12)!important',
                                                backgroundSize: '100% 100%!important',
                                                backgroundOrigin: 'content-box!important',
                                                backgroundRepeat: 'no-repeat!important',
                                            }}
                                        />
                                        <div className="sc-grXZZQ dWIrMz">PulseChain</div>
                                    </div>
                                    <Link
                                        rel="noopener noreferrer"
                                        to={`https://otter.pulsechain.com/address/${address}`}
                                        target="_blank"
                                    >
                                        <div
                                            to={`https://otter.pulsechain.com/address/${address}`}
                                            className="sc-grXZZQ sc-fXitXF ecFtPB iyPLPW"
                                        >
                                            Otterscan
                                            <svg
                                                viewBox="0 0 24 24"
                                                color="primary"
                                                ml="4px"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="sc-bcPKhP gvPvmE"
                                            >
                                                <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z" />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                                <div className="sc-gKHVLF sc-iBAaJG UlmxL feFGPx">
                                    <div className="sc-grXZZQ gLWoSh">PLS Balance</div>
                                    <div className="sc-grXZZQ cdHhbk">0</div>
                                </div>
                                <div className="sc-gKHVLF sc-iBAaJG UlmxL feFGPx">
                                    <div className="sc-grXZZQ gLWoSh">PLSX Balance</div>
                                    <div className="sc-grXZZQ cdHhbk">0</div>
                                </div>
                            </div>
                            <button
                                variant="secondary"
                                width="100%"
                                scale="md"
                                className="sc-hLclGa bTUuyO"
                                onClick={() => { disconnect(); close() }}
                            >
                                Disconnect Wallet
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
