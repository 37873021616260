import React from 'react';

import {
  Link,
  NavLink,
} from 'react-router-dom';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import V1Overview from '../components/V1Overview';
import V1Pools from '../components/V1Pools';
import V1Tokens from '../components/V1Tokens';
import V2Overview from '../components/V2Overview';
import V2Pools from '../components/V2Pools';
import V2Tokens from '../components/V2Tokens';

export default function Info({ walletAction, isV2, isTokens, isPools }) {
  return (
    <>
      <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
        <Navbar walletAction={walletAction} />
        <div className="sc-gKHVLF sc-iBAaJG UlmxL bFuSQM">
          <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr gDrmVh eiQbuK dKnWPh" />
        </div>
        <div className="sc-gKHVLF sc-ehwEHI gWzXuy ggQwmN">
          <div className="sc-djLfiL hMZhUc">
            <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr jfeXqY eiQbuK dKnWPh">
              <div className="sc-gKHVLF fHszqz">
                <div className={!isV2 ? "sc-grFOgM dkUmA-D" : "sc-grFOgM gZNJiV"}>
                  <NavLink to="/info" className={!isV2 ? "sc-ZPwkx hrbPHn" : "sc-ZPwkx dQBfPj"}>
                    V1
                  </NavLink>
                </div>
              </div>
              <div className="sc-gKHVLF fHszqz">
                <div className={isV2 ? "sc-grFOgM dkUmA-D" : "sc-grFOgM gZNJiV"}>
                  <NavLink to="/info/v2" className={isV2 ? "sc-ZPwkx hrbPHn" : "sc-ZPwkx dQBfPj"}>
                    V2
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="sc-gKHVLF sc-iBAaJG sc-geCDGG UlmxL lbwIxd kvIbFN">
              <div className="sc-gKHVLF UlmxL">
                <div variant="subtle" className="sc-bBbNsw kQLbxS">
                  <Link
                    variant="subtle"
                    scale="sm"
                    className={`sc-hLclGa jiLBrK ${!isPools && !isTokens ? "" : "sc-ivDvhZ bnecNP"}`}
                    to={`/info${isV2 ? "/v2" : ""}`}
                  >
                    Overview
                  </Link>
                  <Link
                    className={`sc-hLclGa jiLBrK ${isPools ? "" : "sc-ivDvhZ bnecNP"}`}
                    variant="subtle"
                    scale="sm"
                    to={`/info${isV2 ? "/v2" : ""}/pools`}
                  >
                    Pools
                  </Link>
                  <Link
                    className={`sc-hLclGa jiLBrK ${isTokens ? "" : "sc-ivDvhZ bnecNP"}`}
                    variant="subtle"
                    scale="sm"
                    to={`/info${isV2 ? "/v2" : ""}/tokens`}
                  >
                    Tokens
                  </Link>
                </div>
              </div>
              <div className="sc-gKHVLF loSYbv">
                <div className="sc-ifiqfo goOEHp">
                  <input
                    type="text"
                    placeholder="Search pools or tokens"
                    className="sc-ksJisA sc-gVXZyT dcPxF jrmwzO"
                  />
                  <div className="sc-ddAxym huYYkj" />
                </div>
              </div>
            </div>
            {!isV2 && !isPools && !isTokens ? <V1Overview /> : isV2 && !isPools && !isTokens ? <V2Overview /> : !isV2 && isPools && !isTokens ? <V1Pools /> : !isV2 && !isPools && isTokens ? <V1Tokens />
              : isV2 && isPools && !isTokens ? <V2Pools /> : <V2Tokens />}
          </div>
        </div>
      </div>
      <Footer walletAction={walletAction} />
    </>
  )
}
