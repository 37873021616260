import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

export default function Liquidity({ walletAction, isConnected }) {
    const [isAll, setIsAll] = useState("all")
    return (
        <>
            <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
                <Navbar walletAction={walletAction} />
                <div className="sc-gKHVLF sc-iBAaJG UlmxL bFuSQM">
                    <div className="sc-gKHVLF sc-iBAaJG sc-knwvCr gDrmVh eiQbuK dKnWPh">
                        <div className="sc-gKHVLF fHszqz">
                            <div className="sc-grFOgM gZNJiV">
                                <Link to="/" className="sc-ZPwkx dQBfPj">
                                    Exchange
                                </Link>
                            </div>
                        </div>
                        <div className="sc-gKHVLF fHszqz">
                            <div className="sc-grFOgM dkUmA-D">
                                <Link to="/liquidity" className="sc-ZPwkx hrbPHn">
                                    Liquidity
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-gKHVLF sc-ehwEHI gWzXuy ggQwmN">
                    <div className="sc-djLfiL hMZhUc">
                        <div className="sc-eVRJAz uaifU">
                            <div className="sc-bYUneI fbWnpo sc-ihxOxR iRZkqd">
                                <div className="sc-gKHVLF sc-kMbQoj UlmxL dywlDY">
                                    <div className="sc-gKHVLF sc-iBAaJG sc-hlaTbY UlmxL lbwIxd OigNq">
                                        <div className="sc-gKHVLF sc-iBAaJG bTyHhM feFGPx">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                    <div className="sc-grXZZQ CdzCT">Your Liquidity</div>
                                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh" />
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <div variant="subtle" className="sc-bBbNsw kQLbxS">
                                                    <button
                                                        variant="subtle"
                                                        scale="sm"
                                                        className={`sc-hLclGa jiLBrK ${isAll === "all" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        onClick={() => setIsAll("all")}
                                                    >
                                                        All
                                                    </button>
                                                    <button
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v1" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        variant="subtle"
                                                        scale="sm"
                                                        onClick={() => setIsAll("v1")}
                                                    >
                                                        V1
                                                    </button>
                                                    <button
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v2" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        variant="subtle"
                                                        scale="sm"
                                                        onClick={() => setIsAll("v2")}
                                                    >
                                                        V2
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isConnected ? <div className="sc-ilpitK sc-bwrJq fxqqA-d cgtOUn">
                                        <div className="sc-grXZZQ fRVRyW">No liquidity found.</div>
                                        <div className="sc-gKHVLF sc-iBAaJG hzKEBQ iBqqcJ">
                                            <div className="sc-grXZZQ bWygtE">Don't see a pool you joined?</div>
                                            <Link to="/find">
                                                <button
                                                    id="import-pool-link"
                                                    variant="secondary"
                                                    scale="sm"
                                                    className="sc-hLclGa ieNdHM"
                                                >
                                                    Find other LP tokens
                                                </button>
                                            </Link>
                                        </div>
                                    </div> :
                                        <div className="sc-ilpitK sc-bwrJq fxqqA-d cgtOUn">
                                            <div className="sc-grXZZQ fRVRyW">
                                                Connect to a wallet to view your liquidity.
                                            </div>
                                        </div>}
                                    <div className="sc-hhWzdI flVxpm" style={{ textAlign: 'center' }}>
                                        <Link to="/add/V1">
                                            <button
                                                id="join-pool-button"
                                                width="100%"
                                                variant="primary"
                                                scale="md"
                                                className="sc-hLclGa jmRqFu"
                                            >
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    color="white"
                                                    mr="0.5rem"
                                                    width="20px"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="sc-bcPKhP jHmRdC"
                                                >
                                                    <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" />
                                                </svg>
                                                Add Liquidity
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL kAtWFC" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer walletAction={walletAction} />
        </>
    )
}
