import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Tokenlist from '../components/Tokenlist';
import toks from '../data/tokens.json';

export default function Find({ walletAction }) {
    const [isAll, setIsAll] = useState("v1")
    const [isTokensOpen, setIsTokensOpen] = useState(false)
    const [tokens, setTokens] = useState(toks)
    const [upSelected, setUpSelected] = useState(toks[0])
    const [downSelected, setDownSelected] = useState({})

    const setSelected = (selected, isUp) => isUp ? setUpSelected(selected) : setDownSelected(selected)

    const toggleTokens = () => isTokensOpen ? closeTokens() : openTokens()

    const closeTokens = () => setIsTokensOpen(false)

    const openTokens = (dir = true) => setIsTokensOpen(dir)
    return (
        <>
            {isTokensOpen ? <Tokenlist isUp={isTokensOpen !== "down"} setSelected={setSelected} close={closeTokens} tokens={tokens} upSelected={upSelected} downSelected={downSelected} /> : null}
            <div className="sc-iXhxtM gNnTKD _1xa5z0q0 bd70ut1h0 bd70ut1i8">
                <Navbar walletAction={walletAction} />
                <div className="sc-gKHVLF sc-ehwEHI oUgzS ggQwmN">
                    <div className="sc-djLfiL hMZhUc">
                        <div className="sc-eVRJAz uaifU">
                            <div className="sc-bYUneI fbWnpo sc-ihxOxR iRZkqd">
                                <div className="sc-gKHVLF sc-kMbQoj UlmxL dywlDY">
                                    <div className="sc-gKHVLF sc-iBAaJG sc-hlaTbY UlmxL lbwIxd OigNq">
                                        <div className="sc-gKHVLF sc-iBAaJG bTyHhM feFGPx">
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <Link to="/liquidity">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="22px"
                                                        color="text"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="sc-bcPKhP cvmRst"
                                                    >
                                                        <path d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" />
                                                    </svg>
                                                </Link>
                                                <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                    <div className="sc-grXZZQ CdzCT">Import Pool</div>
                                                    <div className="sc-gKHVLF sc-iBAaJG UlmxL iIFIZh" />
                                                </div>
                                            </div>
                                            <div className="sc-gKHVLF sc-iBAaJG UlmxL lbwIxd">
                                                <div variant="subtle" className="sc-bBbNsw kQLbxS">
                                                    <button
                                                        variant="subtle"
                                                        scale="sm"
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v1" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        onClick={() => setIsAll("v1")}
                                                    >
                                                        V1
                                                    </button>
                                                    <button
                                                        className={`sc-hLclGa jiLBrK ${isAll === "v2" ? "" : "sc-ivDvhZ bnecNP"}`}
                                                        onClick={() => setIsAll("v2")}
                                                        variant="subtle"
                                                        scale="sm"
                                                    >
                                                        V2
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        gap="md"
                                        className="sc-gVQcLu iyIFwq"
                                        style={{ padding: '1rem' }}
                                    >
                                        <button
                                            className="sc-hLclGa iWOLBh sc-eTpjTE cYgLYG"
                                            variant="primary"
                                            scale="md"
                                            onClick={openTokens}
                                        >
                                            <div className="sc-gKHVLF sc-iuCANo UlmxL gdCtnF">
                                                <img
                                                    src={upSelected.img}
                                                    className="sc-lirSmk ilQYRV"
                                                    alt=''
                                                />
                                                <div className="sc-grXZZQ hrVkmN">{upSelected.symbol}</div>
                                            </div>
                                            <svg
                                                viewBox="0 0 24 24"
                                                ml="0.5rem"
                                                color="text"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="sc-bcPKhP fSjsfV"
                                            >
                                                <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z" />
                                            </svg>
                                        </button>
                                        <div className="sc-CNCmo sc-dxlEDv ggbjm cTeGCR">
                                            <svg
                                                viewBox="0 0 24 24"
                                                color="text"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="sc-bcPKhP cvmRst"
                                            >
                                                <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" />
                                            </svg>
                                        </div>
                                        <button
                                            className="sc-hLclGa iWOLBh sc-eTpjTE cYgLYG"
                                            variant="primary"
                                            scale="md"
                                            onClick={() => openTokens('down')}
                                        >
                                            <div className="sc-gKHVLF sc-iuCANo UlmxL gdCtnF sc-grXZZQ cdHhbk">
                                                {downSelected.symbol ? <>
                                                    <img
                                                        src={downSelected.img}
                                                        className="sc-lirSmk ilQYRV"
                                                        alt=''
                                                    />
                                                    <div className="sc-grXZZQ hrVkmN">{downSelected.symbol}</div>
                                                </> : "Select a Token"}
                                            </div>
                                            <svg
                                                viewBox="0 0 24 24"
                                                ml="0.5rem"
                                                color="text"
                                                width="20px"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="sc-bcPKhP fSjsfV"
                                            >
                                                <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z" />
                                            </svg>
                                        </button>
                                        <div className="sc-gKHVLF sc-BbNMx sc-bjKBMW dPsFyr hVIJhk kEAsbx">
                                            <div className="sc-grXZZQ kqfMUH">
                                                Select a token to find your liquidity.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-gKHVLF sc-iBAaJG UlmxL kAtWFC" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer walletAction={walletAction} />
        </>
    )
}
